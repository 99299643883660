<template>
  <v-card @click="$emit('click')" outlined class="mr-2" style="overflow:hidden; min-width:130px" rounded="lg" flat>
    <v-row align="center" no-gutters>
      <v-card class="mr-2" flat tile :color="color" width="6" height="40"> </v-card>

      <v-card color="transparent" flat tile>
        <v-row align="center" no-gutters>
          <span class="text-h5 font-weight-bold">{{ value }}</span>

          <v-col>
            <v-row no-gutters class="mx-3">
              <span style="max-width:100px; min-width:60px" class="text-caption font-weight-regular text-truncate">{{
                title
              }}</span>
            </v-row>

            <v-row v-show="subtitle !== null" no-gutters class="mx-3">
              <span style="margin-top:-5px" class="text-caption font-weight-light">{{ subtitle }}</span>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    value: null,
    title: null,
    subtitle: null,
    dark: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'grey lighten-3',
    },
  },
  computed: {
    valueToDisplay() {
      if (this.value) {
        return this.value
      } else {
        return 0
      }
    },
  },
}
</script>

<style></style>
