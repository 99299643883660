<template>
  <v-card v-if="userMentions.length > 0" class="mb-4" outlined flat rounded="lg" style="overflow:hidden">
    <v-data-table
      v-if="userMentions.length > 0"
      ref="dataTable"
      fixed-header
      v-model="selectedPatients"
      :items="userMentions"
      item-key="_id"
      show-select
      class="elevation-0"
      :items-per-page="3"
      @click:row="handleClick"
      :options.sync="options"
      @page-count="pageCount = $event"
      :page.sync="page"
      hide-default-footer
    >
      <template v-slot:item.data-table-select="{ isSelected, select, item }">
        <v-row align="center" class="fill-height flex-nowrap row-pointer" style="position: relative" no-gutters>
          <template>
            <div
              v-if="item.alert"
              style="position: absolute; top: 0px; left: -18px; width:7px; height:100%; background-color: yellow"
            ></div>
            <div
              v-else
              style="position: absolute; top: 0px; left: -18px; width:7px; height:100%; background-color: #f5f5f5"
            ></div>
          </template>

          <v-simple-checkbox
            color="primary"
            v-ripple
            :value="isSelected"
            @input="select($event)"
            class="mr-2"
          ></v-simple-checkbox>
          <patient-info-cell :item="item.patient" />
          <v-col>
            <v-row align="center" no-gutters>{{ item.note }}</v-row>
          </v-col>
        </v-row>
      </template>

      <template #no-results>
        <v-fade-transition>
          <v-card style="opacity: 0.3" height="200" flat color="transparent">
            <v-row align="center" style="height:100%">
              <v-col>
                <v-row justify="center">
                  <v-icon size="60">mdi-account-off</v-icon>
                </v-row>
                <v-row justify="center">
                  <span>No Matches</span>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-fade-transition>
      </template>

      <template #header.data-table-select="{props}">
        <v-row class="fill-height" align="center" no-gutters>
          <v-simple-checkbox
            :disabled="userMentions.length === 0"
            :value="selectedPatients.length === userMentions.length && userMentions !== 0"
            v-ripple
            color="primary"
            :indeterminate="
              props.indeterminate || (selectedPatients.length > 0 && selectedPatients.length !== userMentions.length)
            "
            @click.stop="toggleSelected(props)"
          ></v-simple-checkbox>

          <data-table-toolbar
            :selectedPatients="selectedPatients"
            :tableTitle="'@' + user.firstName + ' ' + user.lastName"
          />

          <v-spacer />
          <span>
            <span>{{ (page - 1) * options.itemsPerPage + 1 }}</span
            ><span> - </span>
            <span v-if="(page - 1) * options.itemsPerPage + options.itemsPerPage < userMentions.length">{{
              (page - 1) * options.itemsPerPage + options.itemsPerPage
            }}</span>
            <span v-else> {{ userMentions.length }} </span> of {{ userMentions.length }}
          </span>

          <v-btn large :disabled="page === 1" @click="page = page - 1" icon
            ><v-icon size="25">mdi-chevron-left</v-icon></v-btn
          >

          <v-btn large :disabled="page === pageCount" @click="page = page + 1" icon
            ><v-icon size="25">mdi-chevron-right</v-icon></v-btn
          >
        </v-row>
      </template>
    </v-data-table>
    <v-card flat v-if="userMentions.length === 0" height="100">
      <v-row no-gutters justify="center" align="center" class="fill-height">
        <span class="font-weight-regular text--disabled">No patients with unseen alerts at this time</span>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import consts from '@/consts'
import moment from 'moment-timezone'
import HelperMixin from '@/core/mixins/HelperMixin'
import PatientInfoCell from '@/components/populationview/PatientInfoCell.vue'
import DataTableToolbar from '@/components/populationview/DataTableToolbar.vue'

export default {
  mixins: [HelperMixin],
  components: { PatientInfoCell, DataTableToolbar },
  data() {
    return {
      alertFilterOptions: [
        { text: 'Out of range', value: true },
        { text: 'Within range', value: false },
      ],
      options: {},
      pageCount: 0,
      page: 1,
      selectedPatients: [],
    }
  },
  computed: {
    ...mapState('populationview', ['enrolledPatients', 'patientLoadingProgress', 'refreshingTaskboard']),
    ...mapState('auth', ['userMentions', 'userAssignments', 'user']),
    cardHeight() {
      if (this.patientsWithUnseenAlerts.length > 4) {
        return 240
      }
      return null
    },
    metricList() {
      return consts.metricList
    },
    patientsWithUnseenAlerts() {
      let filteredList = this.enrolledPatients

      filteredList = filteredList.filter(function(e) {
        return e.unseenAlert
      })

      return filteredList
    },
  },
  methods: {
    ...mapActions('notes', ['getJustTheLastNote', 'removeChartNoteTagNotification']),
    manageTags() {
      this.$refs.tagManagerDialog.open()
    },
    sendMassText() {
      this.$refs.massText.open()
    },
    toggleSelected() {
      if (this.selectedPatients.length > 0) {
        this.selectedPatients = []
      } else {
        this.selectedPatients = this.$refs.dataTable.$children[0].filteredItems
      }
    },
    relativeTime(value) {
      if (value) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .fromNow()
        }
      }
      return ''
    },
    msToTime(s) {
      if (!s) {
        return '0h 0m 0s'
      }
      let seconds = Math.floor((s / 1000) % 60)
      let minutes = Math.floor((s / (1000 * 60)) % 60)
      let hours = Math.floor(s / (1000 * 60 * 60))

      hours = hours < 10 ? '' + hours : hours
      minutes = minutes < 10 ? '' + minutes : minutes
      seconds = seconds < 10 ? '' + seconds : seconds

      return hours + 'h ' + minutes + 'm ' + seconds + 's'
    },
    handleClick(item) {
      this.$router.push({
        name: 'PatientFeed',
        params: { id: item.patient._id },
      })

      if (item.IDForClearing) {
        this.removeChartNoteTagNotification(item.IDForClearing)
      }
    },
  },
}
</script>

<style lang="css" scoped>
.row-pointer {
  cursor: pointer;
}
</style>

<style></style>
