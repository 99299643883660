<template>
  <v-col cols="2">
    <span style="width:14vw" v-if="item.firstName" class="font-weight-regular text-body-2 d-block text-truncate">
      {{ item.firstName }}
      <span class="font-weight-bold text-body-2">{{ item.lastName }}</span>
      <span class="text--secondary ml-1" v-if="item.ownerPatientID">{{ item.ownerPatientID }}</span>
    </span>
    <!-- <v-row no-gutters style="margin-top: -4px;">
      <span v-if="item.gender" class="text--disabled text-caption">
        {{ item.gender.charAt(0) }} {{ item.age }} · {{ item.displayBirthdate }}
      </span>
    </v-row> -->
  </v-col>
</template>

<script>
export default {
  props: {
    item: { firstName: 'Missing', lastName: 'Missing', age: 'MISSING', displayBirthdate: 'MISSING', gender: 'NULL' },
  },
}
</script>

<style></style>
