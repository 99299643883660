<template>
  <v-menu
    rounded="lg"
    :transition="false"
    v-model="displayMenu"
    :position-x="positionX + 20"
    :position-y="positionY + 20"
    absolute
    offset-y

  >
  <v-card v-if="data"  min-width="280">
  <v-row class="text-body-1 font-weight-regular pa-2" no-gutters>
      <v-icon size="20" class="mr-1" color="primary">mdi-map-marker</v-icon>
      {{data.name.split(data.zip)[0].trim()}}
      <v-spacer/>
      <span class="text-body-1 font-weight-medium">{{data.zip}}</span>
  </v-row>
  <v-simple-table dense style="background-color: transparent;" class="flex">
      <template v-slot:default>
        
        <tbody>
          <tr
            
          >
            <td class="text-left font-weight-medium" >
                Patients
                </td>
            <td class="text-right font-weight-medium">
              {{patientSubsetForDisplay.length}}
            </td>
          </tr>
          <tr
            
          >
            <td class="text-left font-weight-light" >
                Patients out of range
                </td>
            <td class="text-right font-weight-medium">
              {{outOfBounds}}
            </td>
          </tr>
          <tr
            v-if="averageBPForDisplay"
          >
            <td class="text-left font-weight-light" >
                Average Blood Pressure
                </td>
            <td class="text-right font-weight-medium">
              {{averageBPForDisplay}}
            </td>
          </tr>
          <tr
            v-for="(condition, index) in conditionsForDisplay" :key="index"
            v-show="index < 3"
          >
            <td style="max-width:200px;" class="text-left text-truncate font-weight-light" >
                {{condition.icd10}} {{condition.description}}
                </td>
            <td class="text-right font-weight-medium">
              {{Math.floor(condition.count/patientSubsetForDisplay.length * 100)}}%
            </td>
          </tr>
          
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
  </v-menu>
</template>

<script>
import bus from '@/core/helpers/bus'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  data() {
    return {
      items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' },
      ],
      displayMenu: false,
    }
  },
  components: {},
  watch: {
    showMenu(val) {
      this.displayMenu = val
    },
    displayMenu(val) {
      if (!val) {
        bus.$emit('toggleContextMenu', val)
      }
    },
  },
  methods: {
    ...mapActions('patient', ['search', 'clearPatient', 'getPatientCount']),
    ...mapMutations('patient', ['setPatient']),
    modifyTags() {
      bus.$emit('editTags', this.patientContext)
    },
    assignChartToUser() {
      bus.$emit('assignChart', this.patientContext)
    },
    shareChart() {
      bus.$emit('shareChart', this.patientContext)
    },
    navigateToPatientInfo() {
      this.$router.push({
        name: 'PatientInfo',
        params: { id: this.patientContext._id },
      })
    },
    navigateToChartNotes() {
      this.$router.push({
        name: 'ChartNotes',
        params: { id: this.patientContext._id },
      })
    },
    navigateToFeed() {
      this.setPatient(this.patientContext)
      this.$router.push({
        name: 'PatientFeed',
        params: { id: this.patientContext._id },
      })
    },
    navigateToViewAsPatient() {
      this.setPatient(this.patientContext)
      this.$router.push({
        name: 'ViewAsPatient',
        params: { id: this.patientContext._id },
      })
    },
    navigateToMessages() {
      this.$router.push({
        name: 'Messages',
        params: { id: this.patientContext._id },
      })
    },
    navigateToCarePlan() {
      this.$router.push({
        name: 'CarePlan',
        params: { id: this.patientContext._id },
      })
    },
    navigateToPatientChart() {
      this.$router.push({
        name: 'PatientFeed',
        params: { id: this.patientContext._id },
      })
    },
    navigateToChartFollowers() {
      this.$router.push({
        name: 'Followers',
        params: { id: this.patientContext._id },
      })
    },
  },
  computed: {
    ...mapState('auth', ['appTheme', 'user']),
    ...mapState('taskboard', ['enrolledPatients']),
    patientSubsetForDisplay(){
        let vm = this
        return this.enrolledPatients.filter(function(e) {
          return e.zip.includes(vm.data.zip)
        })
    },
    averageBPForDisplay() {
        let numberOfPatientsWithABloodPressureReading = 0
        let totalSystolic = 0
        let totalDiastolic = 0
        this.patientSubsetForDisplay.forEach(patient => {
            if (patient.latestEvents.MEASUREMENT_BLOOD_PRESSURE) {
                numberOfPatientsWithABloodPressureReading = numberOfPatientsWithABloodPressureReading + 1
                totalSystolic = totalSystolic + patient.latestEvents.MEASUREMENT_BLOOD_PRESSURE.eventData.systolicValue
                totalDiastolic = totalDiastolic = totalDiastolic + patient.latestEvents.MEASUREMENT_BLOOD_PRESSURE.eventData.diastolicValue
            }
        })
        if (numberOfPatientsWithABloodPressureReading > 0) {
        return Math.floor(totalSystolic/numberOfPatientsWithABloodPressureReading) + '/' + Math.floor(totalDiastolic/numberOfPatientsWithABloodPressureReading)
        }
        return null
    },
    // averageWeightForDisplay() {
    //     let numberOfPatientsWithAWeightReading = 0
    //     let totalSystolic = 0
    //     let totalDiastolic = 0
    //     this.patientSubsetForDisplay.forEach(patient => {
    //         if (patient.latestEvents.MEASUREMENT_BLOOD_PRESSURE) {
    //             numberOfPatientsWithABloodPressureReading = numberOfPatientsWithABloodPressureReading + 1
    //             totalSystolic = totalSystolic + patient.latestEvents.MEASUREMENT_BLOOD_PRESSURE.eventData.systolicValue
    //             totalDiastolic = totalDiastolic = totalDiastolic + patient.latestEvents.MEASUREMENT_BLOOD_PRESSURE.eventData.diastolicValue
    //         }
    //     })
    //     if (numberOfPatientsWithABloodPressureReading > 0) {
    //     return Math.floor(totalSystolic/numberOfPatientsWithABloodPressureReading) + '/' + Math.floor(totalDiastolic/numberOfPatientsWithABloodPressureReading)
    //     }
    //     return null
    // },
    outOfBounds() {

   
        return this.patientSubsetForDisplay.filter(function(e) {
          return e.alert
        }).length

    },
    conditionsForDisplay() {
        let conditions = []
        this.patientSubsetForDisplay.forEach(patient => {
            patient.conditions.forEach(condition => {
                let item = {icd10: condition.split('|')[0], description: condition.split('|')[1], count: 1}
                let existing = conditions.find(
                    condition => condition.icd10 === item.icd10
                )

                if (
                    !existing
                ) {
                    conditions.push(item)
                } else if (existing) {
                    existing.count = existing.count + 1
                }
            })
        })
        return conditions.sort((a, b) => b.count - a.count)
    }
  },
  props: {
    positionX: {
      default: 0,
    },
    positionY: {
      default: 0,
    },
    data: {
      type: Object,
      default: null,
    },
    showMenu: {
      default: false,
    },
  },
}
</script>

<style></style>
