var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.unenrolledPatients.length > 0),expression:"unenrolledPatients.length > 0"}],staticClass:"mr-1",attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-archive-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Search Archived Patients")])])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"pb-4"},[_c('v-row',{staticClass:"font-weight-bold text-h6 pa-3 px-4",attrs:{"align":"center","no-gutters":"","justify":"space-between"}},[_c('span',[_vm._v("Archived Patients")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.menu = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-text-field',{staticClass:"pt-0 px-4 mb-3 mx-0",attrs:{"label":"Search archived patients","prepend-inner-icon":"mdi-magnify","hide-details":"","background-color":"grey lighten-4","full-width":"","single-line":"","solo":"","flat":"","return-object":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',{staticClass:"pt-0 px-0 mb-3 mx-4",attrs:{"flat":"","color":"grey lighten-4"}},[_c('v-data-table',{staticClass:"row-pointer grey lighten-4 ",staticStyle:{"background-color":"transparent"},attrs:{"dense":"","fixed-header":"","height":"300","headers":_vm.headers,"hide-default-header":"","hide-default-footer":"","items":_vm.filteredUnenrolled,"items-per-page":"50"},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"start","no-gutters":""}},[_c('span',{staticClass:"font-weight-regular text-body-2"},[_vm._v(" "+_vm._s(item.firstName)+" "),_c('span',{staticClass:"font-weight-bold text-body-2"},[_vm._v(_vm._s(item.lastName))]),(item.ownerPatientID)?_c('span',{staticClass:"text--secondary ml-1"},[_vm._v(_vm._s(item.ownerPatientID))]):_vm._e()])])]}},{key:"no-results",fn:function(){return [_c('v-fade-transition',[_c('v-card',{staticStyle:{"opacity":"0.3"},attrs:{"height":"300","flat":"","color":"transparent"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{attrs:{"size":"100"}},[_vm._v("mdi-account-off")])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v("No Matching Records")])])],1)])],1)],1)]},proxy:true},{key:"item.rpmMostRecentData",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"text-caption",attrs:{"no-gutters":""}},[_vm._v(" Last Active: "),(item.rpmMostRecentData)?_c('span',{staticClass:"ml-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.relativeTime(item.rpmMostRecentData))+" ")]):_c('span',{staticClass:"text--secondary ml-1 font-weight-bold"},[_vm._v(" Never Active ")])])]}}])})],1),_c('v-row',{staticClass:"px-4 pt-4",attrs:{"justify":"space-between","no-gutters":""}},[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Done")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }