var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"overflow":"hidden"},attrs:{"outlined":"","flat":"","rounded":"lg"}},[(_vm.highPriorityPatients.length !== 0)?_c('v-data-table',{ref:"dataTable",staticClass:"elevation-0",attrs:{"fixed-header":"","items":_vm.highPriorityPatients,"item-key":"_id","show-select":"","items-per-page":4,"options":_vm.options,"page":_vm.page,"hide-default-footer":""},on:{"click:row":_vm.handleClick,"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var item = ref.item;
return [_c('v-row',{staticClass:"fill-height flex-nowrap row-pointer",staticStyle:{"position":"relative"},attrs:{"align":"center","no-gutters":""}},[[(item.alert)?_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"-18px","width":"7px","height":"100%","background-color":"yellow"}}):(item.highPriority)?_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"-18px","width":"7px","height":"100%","background-color":"#1A76F2"}}):_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"-18px","width":"7px","height":"100%","background-color":"#f5f5f5"}})],_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"mr-2",attrs:{"color":"primary","value":isSelected},on:{"input":function($event){return select($event)}}}),_c('patient-info-cell',{attrs:{"item":item}}),_c('v-col',[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-progress-linear',{staticClass:"my-0 mr-3",staticStyle:{"min-width":"60px","max-width":"60px"},attrs:{"height":"12","rounded":"","value":item.daysProgress}}),_c('span',[_vm._v(_vm._s(item.priorityStatement))])],1)],1)],2)]}},{key:"no-results",fn:function(){return [_c('v-fade-transition',[_c('v-card',{staticStyle:{"opacity":"0.3"},attrs:{"height":"200","flat":"","color":"transparent"}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"align":"center"}},[_c('v-col',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{attrs:{"size":"60"}},[_vm._v("mdi-account-off")])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v("No Matches")])])],1)],1)],1)],1)]},proxy:true},{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
return [_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"disabled":_vm.highPriorityPatients.length === 0,"value":_vm.selectedPatients.length === _vm.highPriorityPatients.length && _vm.highPriorityPatients.length !== 0,"color":"primary","indeterminate":props.indeterminate ||
              (_vm.selectedPatients.length > 0 && _vm.selectedPatients.length !== _vm.highPriorityPatients.length)},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSelected(props)}}}),_c('data-table-toolbar',{attrs:{"selectedPatients":_vm.selectedPatients,"tableTitle":'High Priority Patients'}}),_c('v-spacer'),_c('span',[_c('span',[_vm._v(_vm._s((_vm.page - 1) * _vm.options.itemsPerPage + 1))]),_c('span',[_vm._v(" - ")]),((_vm.page - 1) * _vm.options.itemsPerPage + _vm.options.itemsPerPage < _vm.highPriorityPatients.length)?_c('span',[_vm._v(_vm._s((_vm.page - 1) * _vm.options.itemsPerPage + _vm.options.itemsPerPage))]):_c('span',[_vm._v(" "+_vm._s(_vm.highPriorityPatients.length)+" ")]),_vm._v(" of "+_vm._s(_vm.highPriorityPatients.length)+" ")]),_c('v-btn',{attrs:{"large":"","disabled":_vm.page === 1,"icon":""},on:{"click":function($event){_vm.page = _vm.page - 1}}},[_c('v-icon',{attrs:{"size":"25"}},[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{attrs:{"large":"","disabled":_vm.page === _vm.pageCount,"icon":""},on:{"click":function($event){_vm.page = _vm.page + 1}}},[_c('v-icon',{attrs:{"size":"25"}},[_vm._v("mdi-chevron-right")])],1)],1)]}}],null,false,2957491332),model:{value:(_vm.selectedPatients),callback:function ($$v) {_vm.selectedPatients=$$v},expression:"selectedPatients"}}):_vm._e(),(_vm.highPriorityPatients.length === 0)?_c('v-card',{attrs:{"flat":"","height":"100"}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('span',{staticClass:"font-weight-regular text--disabled"},[_vm._v("No high priority patients at this time")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }