<template>
  <v-card flat rounded="lg" outlined>
    <v-toolbar dense flat>
      <span class="text-body-1 font-weight-medium">Population At a Glance</span>
    </v-toolbar>
    <!-- conditions summary-->

    <v-row v-if="radialBarSeries.length > 0" no-gutters class="flex-nowrap" style=" position: relative">
      <v-col class="flex-shrink-0 flex-grow-0">
        <v-fade-transition>
          <v-card
            v-show="showCaracol"
            style="position: relative;"
            flat
            color="transparent"
            :height="circleDiameter"
            :width="circleDiameter"
            class="ma-4"
          >
            <v-progress-circular
              v-for="(circle, index) in radialBarSeries"
              :key="index"
              :style="circleStyle(index)"
              :rotate="-90"
              :color="radialBarLabels[index].color"
              background-color="grey lighten-4"
              :value="(circle / 100) * 75"
              :width="circleThickness"
              :size="circleDiameter - index * (circleThickness * 3)"
            />
            <v-card
              tile
              flat
              style="position: absolute; top:0;"
              class="pr-2"
              :width="circleDiameter / 2"
              :height="circleDiameter / 2"
            >
              <v-row
                v-for="(label, index) in radialBarLabels"
                :style="circleLabelStyle(index)"
                :key="index"
                justify="start"
                no-gutters
                class="flex-nowrap"
              >
                <v-card
                  class="text-truncate px-2"
                  style="border-radius: 4px 0px 0px 4px; font-size:14px; width:100%"
                  dark
                  flat
                  :color="label.color"
                  :height="circleThickness"
                >
                  <span class="font-weight-bold">{{ label.icd10 }}</span
                  ><span class="font-weight-regular ml-1">{{ label.description }}</span>
                </v-card>
                <v-col>
                  <v-row justify="end" no-gutters>
                    <v-card
                      class="pr-2 font-weight-bold text-body-2"
                      dark
                      :height="circleThickness"
                      tile
                      :color="label.color"
                      style="font-size:14px;"
                      flat
                      min-width="30"
                      ><v-fade-transition>
                        <span v-show="funimate">{{ radialBarSeries[index] }}%</span>
                      </v-fade-transition>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-fade-transition>
      </v-col>
      <v-col class="flex-shrink-1 flex-grow-1">
        <v-row class="pr-4" v-show="index < 10" v-for="(row, index) in conditionCounts2" :key="index" no-gutters>
          <v-card
            style="overflow: hidden"
            color="grey lighten-5"
            height="33"
            v-show="showCaracol"
            flat
            tile
            class="flex ml-8 mb-1"
            rounded
          >
            <v-row class="fill-height mx-3" align="center" style="min-width:100px" no-gutters>
              <span class="font-weight-light"
                ><span class="font-weight-bold">{{ row.icd10 }}</span> {{ row.description }}</span
              >
              <v-spacer />
              <span class="font-weight-bold mr-1">{{ row.count }}</span>
              <span class="font-weight-light">({{ Math.ceil((row.count / filteredArray.length) * 100) }}%)</span>
            </v-row>
            <!-- <tr v-show="index < 7" v-for="(row, index) in conditionCounts2" :key="index">
                <td align="left">
                  <v-row style="min-width:100px" no-gutters>
                    <span class="font-weight-light"
                      ><span class="font-weight-bold">{{ row.icd10 }}</span> {{ row.description }}</span
                    >
                  </v-row>
                </td>
                <td align="right">
                  <v-row justify="end" class="pr-1" style="min-width: 100px">
                    <span class="font-weight-bold mr-1">{{ row.count }}</span>
                    <span class="font-weight-light">({{ Math.ceil((row.count / filteredArray.length) * 100) }}%)</span>
                  </v-row>
                </td>
              </tr> -->
          </v-card>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters v-else>
      <v-col>
        <v-card flat :height="circleDiameter" color="grey lighten-5">
          <v-overlay opacity="0.0" absolute>
            <span class="grey--text">No conditions to display</span>
          </v-overlay>
        </v-card>
      </v-col>
    </v-row>

    <v-toolbar v-if="false" dense flat>
      <span class="text-body-1 font-weight-medium text--secondary">Days since last reading</span>
    </v-toolbar>

    <v-row v-if="false" class="mx-4" align="end" no-gutters>
      <v-col v-for="(value, index) in arrayOfTotals" :key="index">
        <v-card flat tile class="flex mr-1 mt-8" :height="value.value * 150" :color="value.color">
          <v-row style="position: absolute; width: 100%; margin-top: -30px" no-gutters justify="center">
            {{ value.value * 100 }}
          </v-row>
        </v-card>
        <v-row align="center" justify="center" no-gutters>{{ value.title }}</v-row>
      </v-col>
    </v-row>

    <v-row class="pl-4 pr-2 pt-8" no-gutters>
      <pop-stat
        shortTitle="Patient Count"
        :comparisonTotal="enrolledPatients.length"
        :finalValue="filteredArray.length"
        title="Total number of selected patients"
      />

      <pop-stat
        :comparisonTotal="filteredArray.length"
        v-if="addedInTheLast30Days > 0"
        :finalValue="addedInTheLast30Days"
        shortTitle="New Patients"
        title="Patients added in the last 30 days"
      />

      <!-- <pop-stat
        v-if="moreThanOneCondition > 0"
        :finalValue="moreThanOneCondition"
        title="Patients with 2 or more conditions"
      /> -->

      <pop-stat
        shortTitle="Active Today"
        :comparisonTotal="filteredArray.length"
        :finalValue="tookAReadingToday"
        title="Patients who took a reading today"
      />

      <pop-stat
        shortTitle="Active Recently"
        :comparisonTotal="filteredArray.length"
        :finalValue="tookAReadingLast7Days"
        title="Patients who took a reading in the last 7 days"
      />

      <pop-stat
        shortTitle="Messaged Today"
        v-if="messageToday > 0"
        :comparisonTotal="filteredArray.length"
        :finalValue="messageToday"
        title="Patients who were messaged today"
      />

      <pop-stat
        shortTitle="Messaged Recently"
        v-if="messageInLast7Days > 0"
        :finalValue="messageInLast7Days"
        :comparisonTotal="filteredArray.length"
        title="Patients messaged in the last 7 days"
      />
      <pop-stat
        shortTitle="Out of Range"
        :finalValue="outOfRange"
        :comparisonTotal="filteredArray.length"
        title="Patients whose most recent reading is above or below the threshold"
      />
      <pop-stat
        shortTitle="Chart Minutes"
        :finalValue="qhpTimeToDate"
        title="Month-to-date minutes spent in the chart by a qualified healthcare professional"
      />
      <pop-stat
        shortTitle="Inactive Patients"
        v-if="moreThan30SinceLastReading > 0"
        :finalValue="moreThan30SinceLastReading"
        :comparisonTotal="filteredArray.length"
        title="Patients who haven't taken a reading in more than 30 days"
      />
      <pop-stat
        shortTitle="No Data"
        v-if="noData > 0"
        :comparisonTotal="filteredArray.length"
        :finalValue="noData"
        title="Patients who have never taken a reading"
      />

      <pop-stat
        shortTitle="Average Days Enrolled"
        :finalValue="parseInt(averageEnrollmentAge)"
        title="Average length of patient enrollment in days"
      />
      <pop-stat
        shortTitle="Fully Compliant"
        :finalValue="parseInt(onFire)"
        :comparisonTotal="filteredArray.length"
        title="Patients who have met 16 days of readings consistently for the last several periods"
      />

      <pop-stat
        shortTitle="Highly Active"
        :finalValue="parseInt(highCompliancePatients)"
        :comparisonTotal="filteredArray.length"
        title="Patients who average more than 16 days of data per period"
      />

      <pop-stat
        shortTitle="Low Activity"
        :finalValue="parseInt(lowCompliancePatients)"
        :comparisonTotal="filteredArray.length"
        title="Patients who average less than 4 days of data per period"
      />
    </v-row>

    <!-- conditions summary-->
    <v-row v-if="false" align="center" style="height:45px" no-gutters>
      <span class="mx-2 text-body-1 font-weight-medium text--secondary"> Top Conditions</span>
    </v-row>
    <v-row v-if="false" no-gutters class="flex-nowrap" style=" position: relative">
      <v-col class="flex-shrink-0 flex-grow-0">
        <v-card style="position: relative;" flat :height="circleDiameter" :width="circleDiameter">
          <v-progress-circular
            v-for="(circle, index) in radialBarSeries"
            :key="index"
            :style="circleStyle(index)"
            :rotate="-90"
            :color="radialBarLabels[index].color"
            background-color="grey lighten-4"
            :value="(circle / 100) * 75"
            :width="circleThickness"
            :size="circleDiameter - index * (circleThickness * 3)"
          />
          <v-card
            tile
            flat
            style="position: absolute; top:0;"
            class="pr-2"
            :width="circleDiameter / 2"
            :height="circleDiameter / 2"
          >
            <v-row
              v-for="(label, index) in radialBarLabels"
              :style="circleLabelStyle(index)"
              :key="index"
              justify="start"
              no-gutters
              class="flex-nowrap"
            >
              <v-card
                class="text-truncate px-2"
                style="border-radius: 4px 0px 0px 4px; font-size:14px; width:100%"
                dark
                flat
                :color="label.color"
                :height="circleThickness"
              >
                <span class="font-weight-bold">{{ label.icd10 }}</span
                ><span class="font-weight-regular ml-1">{{ label.description }}</span>
              </v-card>
              <v-col>
                <v-row justify="end" no-gutters>
                  <v-card
                    class="pr-2 font-weight-bold text-body-2"
                    dark
                    :height="circleThickness"
                    tile
                    :color="label.color"
                    style="font-size:14px;"
                    flat
                  >
                    {{ radialBarSeries[index] }}%
                  </v-card>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-col>
      <v-col class="flex-shrink-1 flex-grow-1">
        <v-card color="grey lighten-3" outlined rounded="lg" class="flex ml-4">
          <v-simple-table dense :height="circleDiameter" v-if="conditionCounts2.length > 0">
            <template v-slot:default>
              <tbody>
                <tr v-for="(row, index) in conditionCounts2" :key="index">
                  <td align="left">
                    <v-row style="min-width:100px" no-gutters>
                      <span class="font-weight-light"
                        ><span class="font-weight-bold">{{ row.icd10 }}</span> {{ row.description }}</span
                      >
                    </v-row>
                  </td>
                  <td align="right">
                    <v-row justify="end" class="pr-1" style="min-width: 100px">
                      <span class="font-weight-bold mr-1">{{ row.count }}</span>
                      <span class="font-weight-light"
                        >({{ Math.ceil((row.count / filteredArray.length) * 100) }}%)</span
                      >
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row v-if="false" class="mt-4 px-4 pt-6" no-gutters>
    <v-card class="mr-4 mb-4" min-height="300" flat color="transparent" >
    <v-row no-gutters justify="space-between" class="font-weight-bold text-body-1 mx-3 mb-4 text--secondary">
        <span>Patient Activity</span>
    </v-row>

    <v-row v-if="stats" no-gutters>
        <v-card style="border-radius: 15px;" outlined  v-for="(card, index) in stats.cards" :key="index" class="mr-4 my-2 pa-3" width="180">
            <v-card flat color="transparent" class="font-weight-thin" height="140">

                {{card.note}}

            </v-card>
            <v-row no-gutters justify="space-between" class=" font-weight-thin text-h5 pa-0">
                <span class="font-weight-regular">{{card.count}}</span><span v-if="card.percentage" class="text--secondary">{{card.percentage}}%</span>
            </v-row>
        </v-card>
    </v-row>

</v-card>

    </v-row> -->
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment-timezone'
import HelperMixin from '@/core/mixins/HelperMixin'
import PopStat from './PopStat.vue'

export default {
  mixins: [HelperMixin],
  components: { PopStat },
  props: {
    filteredArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stats: null,
      circleDiameter: 360,
      funimate: false,
      showCaracol: false,
    }
  },
  watch: {},
  computed: {
    ...mapState('taskboard', ['enrolledPatients', 'patientZips']),
    tookAReadingInTheLastTwoDays() {
      let cutoff2 = new Date()
      cutoff2.setDate(cutoff2.getDate() - 2)
      cutoff2.setHours(0, 0, 0, 0)

      return this.filteredArray.filter(function(e) {
        return e.mostRecentDataTimestamp > cutoff2.getTime()
      }).length
    },
    tookAReading3To7DaysAgo() {
      let cutoff3 = new Date()
      cutoff3.setDate(cutoff3.getDate() - 2)
      cutoff3.setHours(0, 0, 0, 0)

      let cutoff5 = new Date()
      cutoff5.setDate(cutoff5.getDate() - 7)
      cutoff5.setHours(0, 0, 0, 0)

      return this.filteredArray.filter(function(e) {
        return e.mostRecentDataTimestamp >= cutoff5.getTime() && e.mostRecentDataTimestamp < cutoff3.getTime()
      }).length
    },
    tookAReading7To14DaysAgo() {
      let cutoff3 = new Date()
      cutoff3.setDate(cutoff3.getDate() - 7)
      cutoff3.setHours(0, 0, 0, 0)

      let cutoff5 = new Date()
      cutoff5.setDate(cutoff5.getDate() - 14)
      cutoff5.setHours(0, 0, 0, 0)

      return this.filteredArray.filter(function(e) {
        return e.mostRecentDataTimestamp >= cutoff5.getTime() && e.mostRecentDataTimestamp < cutoff3.getTime()
      }).length
    },
    tookAReading14To30DaysAgo() {
      let cutoff3 = new Date()
      cutoff3.setDate(cutoff3.getDate() - 14)
      cutoff3.setHours(0, 0, 0, 0)

      let cutoff5 = new Date()
      cutoff5.setDate(cutoff5.getDate() - 30)
      cutoff5.setHours(0, 0, 0, 0)

      return this.filteredArray.filter(function(e) {
        return e.mostRecentDataTimestamp >= cutoff5.getTime() && e.mostRecentDataTimestamp < cutoff3.getTime()
      }).length
    },
    tookAReading30To60DaysAgo() {
      let cutoff3 = new Date()
      cutoff3.setDate(cutoff3.getDate() - 30)
      cutoff3.setHours(0, 0, 0, 0)

      let cutoff5 = new Date()
      cutoff5.setDate(cutoff5.getDate() - 60)
      cutoff5.setHours(0, 0, 0, 0)

      return this.filteredArray.filter(function(e) {
        return e.mostRecentDataTimestamp >= cutoff5.getTime() && e.mostRecentDataTimestamp < cutoff3.getTime()
      }).length
    },
    tookAReading60PlusDaysAgo() {
      let cutoff5 = new Date()
      cutoff5.setDate(cutoff5.getDate() - 60)
      cutoff5.setHours(0, 0, 0, 0)

      return this.filteredArray.filter(function(e) {
        return e.mostRecentDataTimestamp < cutoff5.getTime()
      }).length
    },
    arrayOfTotals() {
      let complianceChart = []

      let totalsForMax = [
        this.tookAReadingInTheLastTwoDays,
        this.tookAReading3To7DaysAgo,
        this.tookAReading7To14DaysAgo,
        this.tookAReading14To30DaysAgo,
        this.tookAReading30To60DaysAgo,
        this.tookAReading60PlusDaysAgo,
      ]
      let top = Math.max(...totalsForMax)

      complianceChart.push({ title: '0-2', value: this.tookAReadingInTheLastTwoDays / top, color: 'green darken-1' })
      complianceChart.push({ title: '3 to 7', value: this.tookAReading3To7DaysAgo / top, color: 'green' })
      complianceChart.push({ title: '8 to 14', value: this.tookAReading7To14DaysAgo / top, color: 'green lighten-1' })
      complianceChart.push({ title: '15 to 30', value: this.tookAReading14To30DaysAgo / top, color: 'yellow' })
      complianceChart.push({ title: '31 to 60', value: this.tookAReading30To60DaysAgo / top, color: 'red lighten-1' })
      complianceChart.push({ title: 'More than 60', value: this.tookAReading60PlusDaysAgo / top, color: 'red' })
      return complianceChart
    },
    patientConditions() {
      let arrayOfArrayOfConditions = this.filteredArray.map(a => a.conditions)
      let conditionsWithoutDuplicates = []
      let icd10Codes = []
      arrayOfArrayOfConditions.forEach(patientConditions => {
        patientConditions.forEach(condition => {
          if (!icd10Codes.includes(condition.split('|')[0])) {
            icd10Codes.push(condition.split('|')[0])
            conditionsWithoutDuplicates.push({
              value: condition.split('|')[0],
              text: condition.split('|')[1],
            })
          }
        })
      })
      return conditionsWithoutDuplicates
    },
    circleThickness() {
      return this.circleDiameter * 0.055
    },
    qhpTimeToDate() {
      let qhpTotal = 0
      this.filteredArray.forEach(patient => {
        if (patient.millisecondsThisPeriod) {
          qhpTotal = qhpTotal + patient.millisecondsThisPeriod
        }
      })
      return qhpTotal / 60000
    },
    moreThanOneCondition() {
      let patientsWithMoreThanOneCondition = this.filteredArray.filter(function(e) {
        return e.conditions.length > 1
      }).length

      return patientsWithMoreThanOneCondition
    },
    messageToday() {
      let startOfToday = new Date()
      startOfToday.setHours(0, 0, 0, 0)

      let subsetPatients = this.filteredArray.filter(function(e) {
        return e.lastMessageTimestamp > startOfToday.getTime()
      })
      return subsetPatients.length
    },
    messageInLast7Days() {
      let sevenDays = new Date()
      sevenDays.setDate(sevenDays.getDate() - 7)
      sevenDays.setHours(0, 0, 0, 0)

      let subsetPatients = this.filteredArray.filter(function(e) {
        return e.lastMessageTimestamp > sevenDays.getTime()
      })
      return subsetPatients.length
    },
    tookAReadingToday() {
      let startOfToday = new Date()
      startOfToday.setHours(0, 0, 0, 0)

      let subsetPatients = this.filteredArray.filter(function(e) {
        return e.mostRecentDataTimestamp > startOfToday.getTime()
      })
      return subsetPatients.length
    },
    tookAReadingLast7Days() {
      let sevenDays = new Date()
      sevenDays.setDate(sevenDays.getDate() - 7)
      sevenDays.setHours(0, 0, 0, 0)

      let countToReturn = this.filteredArray.filter(function(e) {
        return e.mostRecentDataTimestamp > sevenDays.getTime()
      }).length

      return countToReturn
    },
    moreThan30SinceLastReading() {
      let monthAgo = new Date()
      monthAgo.setDate(monthAgo.getDate() - 30)
      monthAgo.setHours(0, 0, 0, 0)

      let countToReturn = this.filteredArray.filter(function(e) {
        return e.mostRecentDataTimestamp < monthAgo.getTime()
      }).length

      return countToReturn
    },
    threeDaysInARow() {
      // patients that have taken a reading every other day for a long time?
      let count = 0
      this.filteredArray.forEach(patient => {
        if (
          patient.rpm &&
          patient.rpm.activity &&
          patient.rpm.activity.dates &&
          patient.rpm.activity.dates.length > 0
        ) {
          console.log(patient.rpm.activity.dates[patient.rpm.activity.dates.length - 1])
          count = count + 1
        }
      })

      return count
    },
    outOfRange() {
      let subsetPatients = this.filteredArray.filter(function(e) {
        return e.alert
      })
      return subsetPatients.length
    },
    addedInTheLast30Days() {
      let thirtyDays = new Date()
      thirtyDays.setDate(thirtyDays.getDate() - 30)
      thirtyDays.setHours(0, 0, 0, 0)

      let countToReturn = this.filteredArray.filter(function(e) {
        let enrollment = new Date(e.rpm.enrollment.start).getTime()
        return enrollment > thirtyDays.getTime()
      }).length

      return countToReturn
    },
    noData() {
      let countToReturn = this.filteredArray.filter(function(e) {
        return e.noData
      }).length

      return countToReturn
    },
    averageEnrollmentAge() {
      let enrollmentAges = this.filteredArray.map(a => a.enrollmentAge)
      let totalAge = 0
      enrollmentAges.forEach(age => {
        totalAge = totalAge + age
      })

      return totalAge / enrollmentAges.length
    },
    highCompliancePatients() {
      let filteredList = this.filteredArray

      filteredList = filteredList.filter(function(e) {
        return e.highCompliance
      })

      return filteredList.length
    },
    qualifiedRecently() {
      let filteredList = this.filteredArray

      filteredList = filteredList.filter(function(e) {
        return e.recentQualifications > 0
      })

      return filteredList.length
    },

    onFire() {
      let filteredList = this.filteredArray

      filteredList = filteredList.filter(function(e) {
        return e.onFire
      })

      return filteredList.length
    },

    lowCompliancePatients() {
      let filteredList = this.filteredArray

      filteredList = filteredList.filter(function(e) {
        return e.lowCompliance
      })

      return filteredList.length
    },
    conditionsSeries() {
      let series = []
      let includedCount = 0
      let otherCount = 0
      this.conditionCounts.forEach(card => {
        if (card.count / this.filteredArray.length > 0.02) {
          series.push(card.count)
          includedCount = includedCount + 1
        } else {
          otherCount = otherCount + card.count
        }
      })
      series.push(otherCount)
      return series
    },
    conditionsLabels() {
      let labels = []
      let includedCount = 0
      this.conditionCounts.forEach(card => {
        if (card.count / this.filteredArray.length > 0.02) {
          labels.push(card.icd10sForMatch.sort().join(', '))
          includedCount = includedCount + 1
        }
      })
      labels.push('Other')
      return labels
    },
    radialBarSeries() {
      let series = []
      let includedCount = 0
      let otherCount = 0
      this.conditionCounts2.forEach(card => {
        let value = (card.count / this.filteredArray.length) * 100

        if (value > 1 && includedCount < 6) {
          if (this.funimate) {
            series.push(Math.ceil((card.count / this.filteredArray.length) * 100))
          } else {
            series.push(0)
          }
          includedCount = includedCount + 1
        }
      })
      if (otherCount > 0 && this.funimate) {
        series.push(otherCount)
      } else if (otherCount > 0) {
        series.push(0)
      }

      return series
    },
    radialBarLabels() {
      let colors = ['#0E3C55', '#1495BA', '#A2B86C', '#F16D20', 'primary lighten-2']
      let dark = [false, false, false, false, true]
      let labels = []
      let includedCount = 0
      this.conditionCounts2.forEach(card => {
        let value = (card.count / this.filteredArray.length) * 100

        if (value > 1 && includedCount < 6) {
          card.color = colors[includedCount]
          card.dark = dark[includedCount]
          labels.push(card)
          includedCount = includedCount + 1
        }
      })

      return labels
    },
    conditionCounts2() {
      let compiledCounts = []

      this.patientConditions.forEach(conditionOption => {
        let conditionSubset = this.filteredArray.filter(function(e) {
          return e.conditionsForMatching.includes(conditionOption.value)
        })

        let returnObject = {
          icd10: conditionOption.value,
          description: conditionOption.text,
          count: conditionSubset.length,
        }
        if (conditionSubset.length > 0) {
          compiledCounts.push(returnObject)
        }
      })
      return compiledCounts.sort((a, b) => b.count - a.count)
    },
    chartOptionsDonut() {
      return {
        chart: {
          type: 'donut',
          animations: {
            enabled: false,
          },
        },

        labels: this.conditionsLabels,
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: '75%',
              labels: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
      }
    },
    radialBarOptions() {
      return {
        chart: {
          height: '100%',
          width: '100%',
          type: 'radialBar',
          animations: { enabled: false },
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
        labels: this.radialBarLabels,
        legend: {
          show: true,
          floating: false,
          fontSize: '24px',
          position: 'left',
          offsetX: 0,
          offsetY: 15,
          labels: {
            useSeriesColors: false,
          },
          markers: {
            size: 0,
          },
          formatter: function(seriesName, opts) {
            return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex] + '%'
          },
          itemMargin: {
            vertical: 3,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      }
    },
    chartOptionsMini() {
      return {
        chart: {
          sparkline: { enabled: true },
          animations: {
            enabled: false,
          },
        },
        stroke: { width: 0, curve: 'smooth' },
        fill: {
          opacity: 1,
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.0,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
        xaxis: {
          forceNiceScale: false,
          type: 'datetime',
          show: false,
        },
        tooltip: {
          enabled: true,
        },
      }
    },
    series() {
      if (this.stats.series) {
        return this.stats.series
      }
      return []
    },
  },
  methods: {
    ...mapActions('populationmap', ['getCoordinatesForBatchAddresses']),
    circleStyle(index) {
      return (
        'position: absolute; width:' +
        this.circleDiameter +
        'px; margin-left: 0px; ' +
        'top: ' +
        index * (this.circleThickness * 1.5) +
        'px;'
      )
    },
    msToTime(s) {
      if (!s) {
        return '00:00:00'
      }
      let seconds = Math.floor((s / 1000) % 60)
      let minutes = Math.floor((s / (1000 * 60)) % 60)
      let hours = Math.floor(s / (1000 * 60 * 60))

      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds

      return hours + ':' + minutes + ':' + seconds
    },
    circleLabelStyle() {
      return 'margin-bottom: ' + this.circleThickness * 0.5 + 'px;'
    },
    relativeTime(value) {
      let now = new Date().getTime()
      var Difference_In_Time = now - value

      let days = Difference_In_Time / (3600000 * 24)

      // if tis less than three days ago use moment.
      if (value && Math.floor(days) < 3) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .calendar()
        }
      } else if (value) {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
      return ''
    },
  },
  mounted() {
    this.showCaracol = true
    let vm = this
    setTimeout(() => {
      vm.funimate = true
    }, 300)
  },
}
</script>

<style></style>
