<template>
  <v-container fluid class="pa-0">
    <portal to="appBarContentLeft">
      <v-row class="flex-nowrap" align="center" justify="space-between" no-gutters>
        <span v-if="partner" class="text-no-wrap font-weight-light text-h5 mr-4">
          {{ arrayToUse[selectedCategory].title }}
        </span>
      </v-row>
    </portal>

    <portal to="appBarContentExtension">
      <v-card class="flex" flat style="border-radius: 5px; overflow:hidden">
        <v-row class="flex" no-gutters>
          <v-col v-for="(cat, item) in arrayToUse" :key="item">
            <v-card @click="cardClick(item)" dark tile class="flex" flat :color="cat.color">
              <v-row no-gutters class="fill-height text-h6 font-weight-bold" justify="center" align="center">{{
                cat.count
              }}</v-row>
              <v-row
                v-show="false"
                no-gutters
                class="fill-height text-caption"
                justify="center"
                style="margin-top: -6px;"
                >{{ cat.title }}</v-row
              >
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-card height="40" flat>
            <v-simple-checkbox color="primary"></v-simple-checkbox>
          </v-card>
        </v-row>
      </v-card>
    </portal>

    <portal to="subdrawer">
      <v-list nav>
        <v-row class="py-2 pb-2" no-gutters justify="center">
          <span class="text-no-wrap font-weight-light text-h5">Device Management</span>
        </v-row>
        <v-card v-if="false" min-height="160" flat class="flex mt-5">
          <v-row no-gutters align="center" class="fill-height" justify="center">
            <v-icon color="grey lighten-2" size="100">mdi-devices</v-icon>
          </v-row>
        </v-card>

        <v-tooltip max-width="300" v-for="(cat, index) in arrayToUse" :key="index" right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              :input-value="selectedCategory === index"
              color="primary"
              active-class="font-weight-bold"
              v-bind="attrs"
              v-on="on"
              @click="cardClick(index)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ cat.title }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip dark :color="cat.color" small> {{ cat.count }} </v-chip>
              </v-list-item-action>
            </v-list-item>
          </template>
          <span>{{ cat.description }}</span>
        </v-tooltip>
      </v-list>
    </portal>

    <v-tabs-items style="background-color: white" v-model="selectedCategory">
      <v-tab-item v-for="(item, count) in arrayToUse" :key="count">
        <device-management-data-table :categoryTitle="item.fullTitle" :deviceList="item.list" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DeviceManagementDataTable from '@/components/DeviceManagementDataTable.vue'

export default {
  components: { DeviceManagementDataTable },
  data() {
    return {
      selectedCategory: 0,
      mode: 0,
    }
  },

  computed: {
    ...mapState('rpmv2', ['orgDevices']),
    ...mapState('populationview', [
      'enrolledPatients',
      'patientLoadingProgress',
      'refreshingTaskboard',
      'enrolledPatientsDictionary',
    ]),
    ...mapState('auth', ['partner', 'user']),
    isViewingAssigned() {
      return true
    },
    isViewingUnassigned() {
      return true
    },
    orgDevicesCorrelatedWithEnrolledPatients() {
      let rawDevices = this.orgDevices

      rawDevices.forEach(device => {
        if (device.live && this.enrolledPatientsDictionary[device.patientId] && !this.refreshingTaskboard) {
          device.assignmentEnrolled
          device.patientDetail =
            this.enrolledPatientsDictionary[device.patientId].firstName.charAt(0) +
            '. ' +
            this.enrolledPatientsDictionary[device.patientId].lastName
        } else if (device.live && !this.enrolledPatientsDictionary[device.patientId] && !this.refreshingTaskboard) {
          device.patientDetail = 'Assigned to an unenrolled Patient'
          device.issue = true
        } else if (device.live && this.refreshingTaskboard) {
          device.patientDetail = device.patientId
        }
      })
      return rawDevices
    },
    arrayToUse() {
      let live = this.orgDevicesCorrelatedWithEnrolledPatients.filter(function(e) {
        return e.live
      })

      // let unassigned = this.orgDevices.filter(function(e) {
      //   return !e.live
      // })

      let notLive = this.orgDevicesCorrelatedWithEnrolledPatients.filter(function(e) {
        return !e.live
      })

      return [
        {
          count: live.length,
          color: '#1A76F2',
          title: 'Assigned Devices',
          list: live,
          fullTitle: 'assigned',
          icon: 'mdi-thumb-up',
          total: Math.floor((live.length / this.orgDevicesCorrelatedWithEnrolledPatients.length) * 100),
          description: 'Devices currently assigned to patients',
        },
        {
          count: notLive.length,
          color: '#454545',
          title: 'Unassigned Devices',
          list: notLive,
          fullTitle: 'not assigned',
          icon: 'mdi-thumb-up',
          total: Math.floor((notLive.length / this.orgDevicesCorrelatedWithEnrolledPatients.length) * 100),
          description: 'Devices not currently assigned to patients',
        },
      ]
    },
    percentOfDaysRemainingNeeded() {
      let doingGreat = this.enrolledPatients.filter(function(e) {
        return !e.possibleOffTrack && !e.unenrollCandidate
      })

      let unenrollCandidates = this.enrolledPatients.filter(function(e) {
        return e.unenrollCandidate
      })

      let includedPatients = this.enrolledPatients.filter(function(e) {
        return e.possibleOffTrack && !e.unenrollCandidate
      })

      // let zeroTo25 = includedPatients.filter(function(e) {
      //   return e.perfectionNeeded <= 70
      // })

      let zeroTo50 = includedPatients.filter(function(e) {
        return e.perfectionNeeded <= 80
      })

      // let twentySixTo50 = includedPatients.filter(function(e) {
      //   return e.perfectionNeeded > 70 && e.perfectionNeeded <= 80
      // })

      // let fiftyOneTo75 = includedPatients.filter(function(e) {
      //   return e.perfectionNeeded > 80 && e.perfectionNeeded <= 90
      // })

      // let seventySixTo100 = includedPatients.filter(function(e) {
      //   return e.perfectionNeeded > 90 && e.perfectionNeeded <= 100
      // })

      let severeRisk = includedPatients.filter(function(e) {
        return e.perfectionNeeded > 80 && e.perfectionNeeded <= 100
      })

      let notAChance = includedPatients.filter(function(e) {
        return e.perfectionNeeded > 100
      })

      let totals = [
        {
          count: doingGreat.length,
          color: '#1A76F2',
          title: 'On Track',
          list: doingGreat,
          fullTitle: 'On Track',
          icon: 'mdi-thumb-up',
          total: Math.floor((doingGreat.length / this.enrolledPatients.length) * 100),
        },
        // {
        //   count: zeroTo25.length,
        //   color: '#FBC02D',
        //   title: 'At Risk',
        //   list: zeroTo25,
        //   fullTitle: 'Concerning',
        //   icon: 'mdi-alert-circle',
        //   total: Math.floor((zeroTo25.length / this.enrolledPatients.length) * 100),
        // },
        {
          count: zeroTo50.length,
          color: '#F9A825',
          title: 'Moderate Risk',
          list: zeroTo50,
          fullTitle: 'Moderate Risk',
          icon: 'mdi-alert-circle',
          total: Math.floor((zeroTo50.length / this.enrolledPatients.length) * 100),
        },
        // {
        //   count: fiftyOneTo75.length,
        //   color: '#F57F17',
        //   title: 'Serious Risk',
        //   list: fiftyOneTo75,
        //   fullTitle: 'Serious Risk',
        //   icon: 'mdi-alert-circle',
        //   total: Math.floor((fiftyOneTo75.length / this.enrolledPatients.length) * 100),
        // },
        {
          count: severeRisk.length,
          color: '#E53935',
          title: 'Severe Risk',
          list: severeRisk,
          fullTitle: 'Severe Risk',
          icon: 'mdi-alert-circle',
          total: Math.floor((severeRisk.length / this.enrolledPatients.length) * 100),
        },
        {
          count: notAChance.length,
          color: '#424242',
          title: 'Ineligible',
          list: notAChance,
          fullTitle: 'Ineligible',
          icon: 'mdi-alert-octagon',
          total: Math.floor((notAChance.length / this.enrolledPatients.length) * 100),
        },
        {
          count: unenrollCandidates.length,
          color: '#000000',
          title: 'Inactive',
          list: unenrollCandidates,
          fullTitle: 'Inactive',
          icon: 'mdi-close-circle',
          total: Math.floor((unenrollCandidates.length / this.enrolledPatients.length) * 100),
        },
      ]

      return totals
    },
    actuals() {
      let includedPatients = this.enrolledPatients.filter(function(e) {
        return e.possibleOffTrack
      })
      let startOfToday = new Date()
      startOfToday.setHours(0, 0, 0, 0)

      let todayTotal = includedPatients.filter(function(e) {
        return e.mostRecentDataTimestamp >= startOfToday.getTime()
      })

      let startOfYesterday = new Date()
      startOfYesterday.setDate(startOfToday.getDate() - 1)
      startOfYesterday.setHours(0, 0, 0, 0)

      let yesterdayTotal = includedPatients.filter(function(e) {
        return e.mostRecentDataTimestamp >= startOfYesterday.getTime() && e.mostRecentDataTimestamp < startOfToday
      })

      let startOfSeven = new Date()
      startOfSeven.setDate(startOfToday.getDate() - 7)
      startOfSeven.setHours(0, 0, 0, 0)

      let sevenTotal = includedPatients.filter(function(e) {
        return e.mostRecentDataTimestamp >= startOfSeven.getTime() && e.mostRecentDataTimestamp < startOfYesterday
      })

      let startOfThirty = new Date()
      startOfThirty.setDate(startOfToday.getDate() - 30)
      startOfThirty.setHours(0, 0, 0, 0)

      let thirtyTotal = includedPatients.filter(function(e) {
        return e.mostRecentDataTimestamp >= startOfThirty.getTime() && e.mostRecentDataTimestamp < startOfSeven
      })

      let moreThanThirty = includedPatients.filter(function(e) {
        return e.mostRecentDataTimestamp < startOfThirty.getTime()
      })

      let neverTotal = includedPatients.filter(function(e) {
        return !e.mostRecentDataTimestamp
      })

      let totals = [
        {
          count: todayTotal.length,
          color: '#1A76F2',
          title: 'Today',
          list: todayTotal,
          fullTitle: 'Active Today',
        },
        {
          count: yesterdayTotal.length,
          color: '#F9A825',
          title: 'Yesterday',
          list: yesterdayTotal,
          fullTitle: 'Last Active Yesterday',
        },
        {
          count: sevenTotal.length,
          color: '#F57F17',
          title: '3-7 Days',
          list: sevenTotal,
          fullTitle: 'Last Active 3-7 Days Ago',
        },
        {
          count: thirtyTotal.length,
          color: '#F57F17',
          title: '8-30 Days',
          list: thirtyTotal,
          fullTitle: 'Last Active 8-30 Days Ago',
        },
        {
          count: moreThanThirty.length,
          color: '#E53935',
          title: '31+ Days',
          list: moreThanThirty,
          fullTitle: 'Last Active more than 30 Days Ago',
        },
        {
          count: neverTotal.length,
          color: '#000000',
          title: 'Never',
          list: neverTotal,
          fullTitle: 'Never Active',
        },
      ]

      return totals
    },
    chartOptions() {
      let vm = this
      let options = {
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: false,
                name: {
                  show: false,
                },
                value: {
                  formatter: function(item) {
                    return Math.ceil((item / vm.patientCount) * 100) + '%'
                  },
                },
              },
            },
          },
        },
        chart: {
          type: 'donut',
          events: {
            dataPointSelection: function(_, chartContext, config) {
              vm.selectedCategory = config.dataPointIndex
            },
            dataPointMouseEnter: function(event) {
              event.path[0].style.cursor = 'pointer'
            },
          },
        },
        labels: this.arrayToUse.map(x => x.title),
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: this.arrayToUse.map(x => x.color),
      }
      return options
    },
    chartData() {
      return this.arrayToUse.map(x => x.count)
    },
    patientCount() {
      return this.arrayToUse.map(x => x.count).reduce((a, b) => a + b)
    },
    riskFactorCategories() {
      let includedPatients = this.enrolledPatients.filter(function(e) {
        return e.possibleOffTrack
      })

      let doingGreat = this.enrolledPatients.filter(function(e) {
        return !e.possibleOffTrack
      })

      let zeroTo25 = includedPatients.filter(function(e) {
        return e.severity <= 25
      })

      let twentySixTo50 = includedPatients.filter(function(e) {
        return e.severity > 25 && e.severity <= 50
      })

      let fiftyOneTo75 = includedPatients.filter(function(e) {
        return e.severity > 50 && e.severity <= 75
      })

      let seventySixTo100 = includedPatients.filter(function(e) {
        return e.severity > 75 && e.severity <= 100
      })

      let notAChance = includedPatients.filter(function(e) {
        return e.severity > 100
      })

      let totals = [
        {
          count: doingGreat.length,
          color: 'green darken-1',
          title: 'Doing Great',
          list: doingGreat,
          fullTitle: 'Doing Great',
        },
        {
          count: zeroTo25.length,
          color: 'yellow darken-1',
          title: 'Concerning',
          list: zeroTo25,
          fullTitle: 'Concerning',
        },
        {
          count: twentySixTo50.length,
          color: 'yellow darken-2',
          title: 'twentySixTo50',
          list: twentySixTo50,
          fullTitle: 'twentySixTo50',
        },
        {
          count: fiftyOneTo75.length,
          color: 'yellow darken-3',
          title: 'fiftyOneTo75',
          list: fiftyOneTo75,
          fullTitle: 'fiftyOneTo75',
        },
        {
          count: seventySixTo100.length,
          color: 'yellow darken-4',
          title: 'seventySixTo100',
          list: seventySixTo100,
          fullTitle: 'seventySixTo100',
        },
        {
          count: notAChance.length,
          color: 'red',
          title: 'notAChance',
          list: notAChance,
          fullTitle: 'notAChance',
        },
      ]

      return totals
    },
  },
  methods: {
    ...mapActions('rpmv2', [
      'assignDevice',
      'getPatient',
      'getOrgDevices',
      'getPatientDevices',
      'getOrCreateDevice',
    ]),
    cardClick(cat) {
      this.selectedCategory = cat
    },
    graphClick() {
      console.log('clicked)')
    },
  },
  async mounted() {
    await this.getOrgDevices(this.user.partnerID)
  },
}
</script>

<style lang="scss" scoped>
.progress-circular-underlay-stroke {
  color: blue;
}
.inactiveClass {
  opacity: 0.6;
}
</style>
