<template>
  <v-data-table
    v-if="patientList.length > 0"
    ref="dataTable"
    fixed-header
    v-model="selectedPatients"
    :items="patientList"
    item-key="_id"
    show-select
    class="elevation-0"
    :items-per-page="25"
    @click:row="handleClick"
    :options.sync="options"
    @page-count="pageCount = $event"
    :page.sync="page"
    :header-props="headerProps"
    height="calc(100vh - 114px)"
    :headers="headers"
    hide-default-footer
    :item-class="rowClass"
  >
    <!-- <template v-slot:item.data-table-select="{ isSelected, select, item }">
      <v-row align="center" class="fill-height flex-nowrap row-pointer" style="position: relative" no-gutters>
        <template>
          <div
            v-if="item.alert"
            style="position: absolute; top: 0px; left: -18px; width:7px; height:100%; background-color: yellow"
          ></div>
          <div
            v-else
            style="position: absolute; top: 0px; left: -18px; width:7px; height:100%; background-color: #f5f5f5"
          ></div>
        </template>

        <v-simple-checkbox
          color="primary"
          v-ripple
          :value="isSelected"
          @input="select($event)"
          class="mr-2"
        ></v-simple-checkbox>
        <patient-info-cell class="mr-5" :item="item" />

        <v-col class="pl-4">
          <v-row no-gutters style="margin-bottom: -4px;">
            <span class="text--disabled text-caption text-truncate">
              Last Activity
            </span>
          </v-row>
          <v-row align="center" class="flex-nowrap text-body-2" no-gutters>
            <span class="text-truncate">
              <span class="font-weight-bold mr-1">{{ item.relativeLastReading }}</span>
            </span>
          </v-row>
        </v-col>
        <v-col>
          <v-row no-gutters style="margin-bottom: -4px;">
            <span style="max-width: 10vw" class="text--disabled text-caption text-truncate">
              Days Remaining
            </span>
          </v-row>
          <v-row align="center" class="flex-nowrap text-body-2" no-gutters>
            <span style="max-width: 20vw" class="text-truncate">
              <span class="font-weight-bold mr-1">{{ Math.floor(item.daysUntilPeriodEnd) }}</span>
            </span>
          </v-row>
        </v-col>
        <v-col>
          <v-row no-gutters style="margin-bottom: -4px;">
            <span style="max-width: 10vw" class="text--disabled text-caption text-truncate">
              Days With Readings
            </span>
          </v-row>
          <v-row align="center" class="flex-nowrap text-body-2" no-gutters>
            <span style="max-width: 20vw" class="text-truncate">
              <span class="font-weight-bold mr-1">{{ item.daysThisPeriodWithReadings }}</span>
            </span>
          </v-row>
        </v-col>
        <v-col>
          <v-row no-gutters style="margin-bottom: -4px;">
            <span style="max-width: 10vw" class="text--disabled text-caption text-truncate">
              Needed
            </span>
          </v-row>
          <v-row align="center" class="flex-nowrap text-body-2" no-gutters>
            <span style="max-width: 20vw" class="text-truncate">
              <span class="font-weight-bold mr-1">{{ 100 - item.howLazyCanYouBe }}%</span>
            </span>
          </v-row>
        </v-col>
        <v-col v-show="false">
          <v-row no-gutters>
            <span style="max-width: 10vw" class="font-weight-medium text-subtitle-2 text-truncate"
              ><v-icon class="mr-1" small>mdi-clock</v-icon>{{ msToTime(item.millisecondsThisPeriod) }}</span
            >
          </v-row>
          <v-row class="pl-5" style="margin-top: -4px;" no-gutters>
            <span style="max-width: 10vw" class="text--disabled text-caption text-truncate">
              {{ getMonthAndYearFromTimestamp(new Date().getTime()) }}
            </span>
          </v-row>
        </v-col>
      </v-row>
    </template> -->

    <template v-slot:top>
      <v-card tile height="50">
        <v-row class="px-2 fill-height" align="center" no-gutters>
          <data-table-toolbar :selectedPatients="selectedPatients" tableTitle="Select All" />

          <v-spacer />
          <span class="text-body-2 font-weight-bold text--secondary">
            <span>{{ (page - 1) * options.itemsPerPage + 1 }}</span
            ><span> - </span>
            <span v-if="(page - 1) * options.itemsPerPage + options.itemsPerPage < patientList.length">{{
              (page - 1) * options.itemsPerPage + options.itemsPerPage
            }}</span>
            <span v-else> {{ patientList.length }} </span> of {{ patientList.length }}
          </span>

          <v-btn large :disabled="page === 1" @click="page = page - 1" icon
            ><v-icon size="25">mdi-chevron-left</v-icon></v-btn
          >

          <v-btn large :disabled="page === pageCount" @click="page = page + 1" icon
            ><v-icon size="25">mdi-chevron-right</v-icon></v-btn
          >
        </v-row>
      </v-card>
    </template>

    <template v-slot:header.millisecondsThisPeriod>
      {{ getMonthAndYearFromTimestamp(new Date().getTime()) }}
    </template>

    <template v-slot:header.data-table-select>
      <v-simple-checkbox
        :disabled="patientList.length === 0"
        :value="selectedPatients.length === patientList.length && patientList !== 0"
        v-ripple
        color="primary"
        :indeterminate="
          headerProps.indeterminate || (selectedPatients.length > 0 && selectedPatients.length !== patientList.length)
        "
        @click.stop="toggleSelected(headerProps)"
      ></v-simple-checkbox>
    </template>

    <!-- <template v-slot:top>
      <v-row align="center" no-gutters>
        <v-simple-checkbox
          :disabled="patientList.length === 0"
          :value="selectedPatients.length === patientList.length && patientList !== 0"
          v-ripple
          color="primary"
          :indeterminate="
            headerProps.indeterminate || (selectedPatients.length > 0 && selectedPatients.length !== patientList.length)
          "
          @click.stop="toggleSelected(headerProps)"
        ></v-simple-checkbox>

        <data-table-toolbar :selectedPatients="selectedPatients" tableTitle="Select All" />

        <v-spacer />
        <span>
          <span>{{ (page - 1) * options.itemsPerPage + 1 }}</span
          ><span> - </span>
          <span v-if="(page - 1) * options.itemsPerPage + options.itemsPerPage < patientList.length">{{
            (page - 1) * options.itemsPerPage + options.itemsPerPage
          }}</span>
          <span v-else> {{ patientList.length }} </span> of {{ patientList.length }}
        </span>

        <v-btn large :disabled="page === 1" @click="page = page - 1" icon
          ><v-icon size="25">mdi-chevron-left</v-icon></v-btn
        >

        <v-btn large :disabled="page === pageCount" @click="page = page + 1" icon
          ><v-icon size="25">mdi-chevron-right</v-icon></v-btn
        >
      </v-row>
    </template> -->
    <template v-slot:item.daysLeftToUse="{ item }">
      <span v-if="item.daysLeftToUse">{{ item.daysLeftToUse }}</span
      ><span v-else>-</span>
    </template>

    <template v-slot:item.lastName="{ item }"> {{ item.firstName }} {{ item.lastName }} </template>

    <template v-slot:item.perfectionNeeded="{ item }">
      {{ normalizePerfectionNeeded(item) }}
    </template>

    <template v-slot:item.millisecondsThisPeriod="{ item }">
      <span v-if="item.millisecondsThisPeriod > 0">{{ msToTime(item.millisecondsThisPeriod) }}</span
      ><span v-else>-</span>
    </template>

    <template v-slot:item.rpmMostRecentData="{ item }">
      <span v-if="item.rpmMostRecentData">{{ relativeDate(Date.parse(item.rpmMostRecentData)) }}</span
      ><span v-else>-</span>
    </template>
    <template #no-results>
      <v-fade-transition>
        <v-card style="opacity: 0.3" height="200" flat color="transparent">
          <v-row align="center" style="height:100%">
            <v-col>
              <v-row justify="center">
                <v-icon size="60">mdi-account-off</v-icon>
              </v-row>
              <v-row justify="center">
                <span>No Matches</span>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-fade-transition>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from 'vuex'
import consts from '@/consts'
import moment from 'moment-timezone'
import HelperMixin from '@/core/mixins/HelperMixin'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/relativeTime'
//import PatientInfoCell from '@/components/populationview/PatientInfoCell.vue'
import DataTableToolbar from '@/components/PatientActivityDataTableToolbar.vue'

export default {
  mixins: [HelperMixin],
  components: { DataTableToolbar },
  props: {
    patientList: {
      type: Array,
      default: () => [],
    },
    categoryTitle: {
      type: String,
      default: 'Category',
    },
  },
  data() {
    return {
      selectedPatients: [],

      headerProps: {},
      alertFilterOptions: [
        { text: 'Out of range', value: true },
        { text: 'Within range', value: false },
      ],
      options: {},
      pageCount: 0,
      page: 1,
    }
  },
  computed: {
    ...mapState('populationview', ['enrolledPatients', 'patientLoadingProgress', 'refreshingTaskboard']),
    headers() {
      return [
        {
          text: 'Patient',
          align: 'start',
          sortable: true,
          value: 'lastName',
        },
        {
          text: 'Last Active',
          align: 'center',
          sortable: true,
          value: 'rpmMostRecentData',
        },
        {
          text: 'Readings',
          align: 'center',
          sortable: true,
          value: 'daysThisPeriodWithReadings',
        },
        {
          text: 'Days left',
          align: 'center',
          sortable: true,
          value: 'daysLeftToUse',
        },
        {
          text: 'Risk Level',
          align: 'center',
          sortable: true,
          value: 'perfectionNeeded',
        },
        {
          text: 'QHP Time',
          align: 'center',
          sortable: true,
          value: 'millisecondsThisPeriod',
        },
      ]
    },
    metricList() {
      return consts.metricList
    },
  },
  methods: {
    rowClass() {
      const rowClass = 'myclass'
      return rowClass
    },
    relativeDate(iso) {
      dayjs.extend(calendar)
      let days = dayjs(iso)
      return days.fromNow()
    },
    manageTags() {
      this.$refs.tagManagerDialog.open()
    },
    sendMassText() {
      this.$refs.massText.open()
    },
    toggleSelected() {
      if (this.selectedPatients.length > 0) {
        this.selectedPatients = []
      } else {
        this.selectedPatients = this.$refs.dataTable.$children[0].filteredItems
      }
    },
    relativeTime(value) {
      if (value) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .fromNow()
        }
      }
      return ''
    },
    msToTime(s) {
      if (!s) {
        return '0h 0m 0s'
      }
      let seconds = Math.floor((s / 1000) % 60)
      let minutes = Math.floor((s / (1000 * 60)) % 60)
      let hours = Math.floor(s / (1000 * 60 * 60))

      hours = hours < 10 ? '' + hours : hours
      minutes = minutes < 10 ? '' + minutes : minutes
      seconds = seconds < 10 ? '' + seconds : seconds

      return hours + 'h ' + minutes + 'm ' + seconds + 's'
    },
    handleClick(patient) {
      this.$router.push({
        name: 'PatientFeed',
        params: { id: patient._id },
      })
    },
    normalizePerfectionNeeded(item) {
      if (item.perfectionNeeded < 0) {
        return '0'
      }

      if (item.perfectionNeeded > 100) {
        return '-'
      }

      if (!item.perfectionNeeded) {
        return '-'
      }

      return item.perfectionNeeded
    },
  },
}
</script>

<style>
.row-pointer {
  cursor: pointer;
}
.myclass {
  cursor: pointer;
}
</style>

<style></style>
