<template>
  <v-row no-gutters>
    <div id="map" style="width: 100%; height: 430px; outline: none;"></div>
  </v-row>
</template>

<script>
import Mapbox from 'mapbox-gl'
import { mapActions, mapState } from 'vuex'

export default {
  components: {},
  props: {
    filteredArray: null,
  },
  data() {
    return {
      mapPoints: [],
      map: null,
      accessToken:
        'pk.eyJ1IjoiZGJyb2RlZ2FyZCIsImEiOiJja21qdWVpNTgwcm1mMnZsZjBma2ltczh3In0.fiEoNlKkN34OZVH6yJ4W0A', // your access token. Needed if you using Mapbox maps
      center: [-71.16770747608763, 42.363448626980635],
      geojson: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              message: 'Foo',
              iconSize: [60, 60],
            },
            geometry: {
              type: 'Point',
              coordinates: [-66.324462890625, -16.024695711685304],
            },
          },
          {
            type: 'Feature',
            properties: {
              message: 'Bar',
              iconSize: [50, 50],
            },
            geometry: {
              type: 'Point',
              coordinates: [-61.2158203125, -15.97189158092897],
            },
          },
          {
            type: 'Feature',
            properties: {
              message: 'Baz',
              iconSize: [40, 40],
            },
            geometry: {
              type: 'Point',
              coordinates: [-63.29223632812499, -18.28151823530889],
            },
          },
        ],
      },
    }
  },
  watch: {
    patientZips(val) {
      console.log(val)
      this.getCoordinatesForBatchAddresses(val)
    },
    coordinates() {
      this.setMapItems()
    },
  },
  computed: {
    ...mapState('populationmap', ['coordinates']),
    ...mapState('taskboard', ['patientZips']),
  },
  methods: {
    ...mapActions('populationmap', ['getCoordinatesForBatchAddresses']),
    setMapItems() {
      let interiorCoordinates = this.coordinates.map(function(item) {
        return [item.center[0], item.center[1]]
      })

      let vm = this
      this.coordinates.forEach(function(marker) {
        // create a DOM element for the marker
        var el = document.createElement('div')
        el.className = 'marker'
        el.classList.add('markerStyle')
        // el.style.backgroundColor = '#1C76F2'

        // el.style.height = '14px'
        // el.style.width = '14px'

        // el.style.overflow = 'hidden'
        // el.style.borderRadius = '50%'

        // el.style.borderWidth = '5px'
        // el.style.border = '2px solid #FFFFFF'
        // el.style.boxShadow = '0px 0px 3px 1px #00000040'
        // el.style.cursor = 'pointer'
        let inner = vm
        function overMouse(e) {
          inner.$emit('hoverEvent', { x: e.pageX, y: e.pageY, data: marker })
        }
        function mouseOut(e) {
          inner.$emit('hoverCancel', { x: e.pageX, y: e.pageY, data: marker })
        }

        function mousePress(e) {
          console.log(e)
          inner.$emit('zipCodeClick', marker.zip)
        }
        el.addEventListener('click', mousePress)
        el.addEventListener('mouseenter', overMouse)
        el.addEventListener('mouseleave', mouseOut)

        // var el = document.createElement('div')
        // el.className = 'marker'
        // el.style.backgroundColor = '#EDEDED'

        // el.innerHTML = marker.zip
        // el.style.overflow = 'hidden'
        // el.style.borderRadius = '10%'

        // el.style.borderWidth = '5px'
        // el.style.border = "2px solid #FFFFFF"
        // el.style.boxShadow = "0px 0px 3px 1px #00000040";

        // el.style.padding = "1px 4px";

        // add marker to map
        if (!vm.mapPoints.includes(marker.zip)) {
          vm.mapPoints.push(marker.zip)
          new Mapbox.Marker(el).setLngLat(marker.center).addTo(vm.map)
        }
      })

      var bounds = interiorCoordinates.reduce(function(bounds, coord) {
        return bounds.extend(coord)
      }, new Mapbox.LngLatBounds(
        interiorCoordinates[0],
        interiorCoordinates[0]
      ))

      this.map.fitBounds(bounds, {
        padding: 40,
      })
    },
  },
  mounted() {
    let currentZips = []

    this.filteredArray.forEach(patient => {
      if (patient.zip && !currentZips.includes(patient.zip)) {
        currentZips.push({ count: 1, text: patient.zip })
      }
    })

    console.log('current zips')
    console.log(currentZips)

    //     var nav = new Mapbox.NavigationControl();
    // this.map.addControl(nav, 'top-left')

    this.getCoordinatesForBatchAddresses(currentZips)
    Mapbox.accessToken = this.accessToken
    this.map = new Mapbox.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/dbrodegard/ckmk1j4oz1c5h17nrfuisw7dg', // style URL
      center: [-111.8803726417694, 40.43348367502826], // starting position [lng, lat]
      zoom: 5, // starting zoom
      attributionControl: true,
    })

    //     var nav = new Mapbox.NavigationControl();
    // this.map.addControl(nav, 'top-left')

    //this.getCoordinatesForBatchAddresses(this.patientZips)
    console.log(this.patientZips)
    if (this.coordinates.length > 0) {
      this.setMapItems()
    }
  },
}
</script>
<style lang="scss">
.markerStyle {
  background-color: #1c76f2;

  height: 14px;
  width: 14px;

  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 3px 1px #00000040;
  cursor: pointer;
}

.markerStyle:hover {
  background-color: white;
  height: 18px;
  width: 18px;
}
.mapboxgl-canvas {
  outline: none;
}

.icon-dc {
  background: #3bb2d0;
}
</style>
