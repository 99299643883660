<template>
  <v-data-table
    ref="dataTable"
    fixed-header
    v-model="selectedDevices"
    :items="deviceList"
    item-key="_id"
    class="elevation-0"
    :items-per-page="25"
    :options.sync="options"
    @page-count="pageCount = $event"
    :page.sync="page"
    :header-props="headerProps"
    height="calc(100vh - 114px)"
    :headers="headers"
    hide-default-footer
  >
    <template v-slot:top>
      <v-card tile height="50">
        <v-row class="px-2 fill-height" align="center" no-gutters>
          <v-spacer />
          <span class="text-body-2 font-weight-bold text--secondary">
            <span>{{ (page - 1) * options.itemsPerPage + 1 }}</span
            ><span> - </span>
            <span v-if="(page - 1) * options.itemsPerPage + options.itemsPerPage < deviceList.length">{{
              (page - 1) * options.itemsPerPage + options.itemsPerPage
            }}</span>
            <span v-else> {{ deviceList.length }} </span> of {{ deviceList.length }}
          </span>

          <v-btn large :disabled="page === 1" @click="page = page - 1" icon
            ><v-icon size="25">mdi-chevron-left</v-icon></v-btn
          >

          <v-btn large :disabled="page === pageCount" @click="page = page + 1" icon
            ><v-icon size="25">mdi-chevron-right</v-icon></v-btn
          >
        </v-row>
      </v-card>
    </template>

    <template v-slot:header.millisecondsThisPeriod>
      {{ getMonthAndYearFromTimestamp(new Date().getTime()) }}
    </template>

    <!-- <template v-slot:top>
      <v-row align="center" no-gutters>
        <v-simple-checkbox
          :disabled="deviceList.length === 0"
          :value="selectedDevices.length === deviceList.length && deviceList !== 0"
          v-ripple
          color="primary"
          :indeterminate="
            headerProps.indeterminate || (selectedDevices.length > 0 && selectedDevices.length !== deviceList.length)
          "
          @click.stop="toggleSelected(headerProps)"
        ></v-simple-checkbox>

        <data-table-toolbar :selectedDevices="selectedDevices" tableTitle="Select All" />

        <v-spacer />
        <span>
          <span>{{ (page - 1) * options.itemsPerPage + 1 }}</span
          ><span> - </span>
          <span v-if="(page - 1) * options.itemsPerPage + options.itemsPerPage < deviceList.length">{{
            (page - 1) * options.itemsPerPage + options.itemsPerPage
          }}</span>
          <span v-else> {{ deviceList.length }} </span> of {{ deviceList.length }}
        </span>

        <v-btn large :disabled="page === 1" @click="page = page - 1" icon
          ><v-icon size="25">mdi-chevron-left</v-icon></v-btn
        >

        <v-btn large :disabled="page === pageCount" @click="page = page + 1" icon
          ><v-icon size="25">mdi-chevron-right</v-icon></v-btn
        >
      </v-row>
    </template> -->
    <template v-slot:item.unassign="{ item }">
      <v-row no-gutters justify="end">
        <v-btn v-if="item.live" depressed @click="unassignDevice(item)" small color="red darken-1" dark>unassign</v-btn>
      </v-row>
    </template>

    <template #no-results>
      <v-fade-transition>
        <v-card style="opacity: 0.3" height="200" flat color="transparent">
          <v-row align="center" style="height:100%">
            <v-col>
              <v-row justify="center">
                <v-icon size="60">mdi-account-off</v-icon>
              </v-row>
              <v-row justify="center">
                <span>No Matches</span>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-fade-transition>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from 'vuex'
import consts from '@/consts'
import moment from 'moment-timezone'
import HelperMixin from '@/core/mixins/HelperMixin'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/relativeTime'
//import PatientInfoCell from '@/components/populationview/PatientInfoCell.vue'

export default {
  mixins: [HelperMixin],
  components: {},
  props: {
    deviceList: {
      type: Array,
      default: () => [],
    },
    categoryTitle: {
      type: String,
      default: 'Category',
    },
  },
  data() {
    return {
      selectedDevices: [],

      headerProps: {},
      alertFilterOptions: [
        { text: 'Out of range', value: true },
        { text: 'Within range', value: false },
      ],
      options: {},
      pageCount: 0,
      page: 1,
    }
  },
  computed: {
    ...mapState('populationview', [
      'enrolledPatients',
      'patientLoadingProgress',
      'refreshingTaskboard',
      'enrolledPatientsDictionary',
    ]),
    headers() {
      return [
        {
          text: 'Device ID',
          align: 'start',
          sortable: true,
          value: 'deviceRefId',
        },
        {
          text: 'Kind',
          align: 'center',
          sortable: true,
          value: 'kind',
        },
        {
          text: 'Assignment',
          align: 'center',
          sortable: true,
          value: 'patientDetail',
        },
        {
          text: '',
          align: 'center',
          sortable: true,
          value: 'unassign',
        },
      ]
    },
    metricList() {
      return consts.metricList
    },
  },
  methods: {
    rowClass() {
      const rowClass = 'myclass'
      return rowClass
    },
    relativeDate(iso) {
      dayjs.extend(calendar)
      let days = dayjs(iso)
      return days.fromNow()
    },
    manageTags() {
      this.$refs.tagManagerDialog.open()
    },
    sendMassText() {
      this.$refs.massText.open()
    },
    toggleSelected() {
      if (this.selectedDevices.length > 0) {
        this.selectedDevices = []
      } else {
        this.selectedDevices = this.$refs.dataTable.$children[0].filteredItems
      }
    },
    relativeTime(value) {
      if (value) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .fromNow()
        }
      }
      return ''
    },
    msToTime(s) {
      if (!s) {
        return '0h 0m 0s'
      }
      let seconds = Math.floor((s / 1000) % 60)
      let minutes = Math.floor((s / (1000 * 60)) % 60)
      let hours = Math.floor(s / (1000 * 60 * 60))

      hours = hours < 10 ? '' + hours : hours
      minutes = minutes < 10 ? '' + minutes : minutes
      seconds = seconds < 10 ? '' + seconds : seconds

      return hours + 'h ' + minutes + 'm ' + seconds + 's'
    },
    handleClick(patient) {
      this.$router.push({
        name: 'PatientFeed',
        params: { id: patient._id },
      })
    },
    normalizePerfectionNeeded(item) {
      if (item.perfectionNeeded < 0) {
        return '0'
      }

      if (item.perfectionNeeded > 100) {
        return '-'
      }

      if (!item.perfectionNeeded) {
        return '-'
      }

      return item.perfectionNeeded
    },
  },
}
</script>

<style>
.row-pointer {
  cursor: pointer;
}
.myclass {
  cursor: pointer;
}
</style>

<style></style>
