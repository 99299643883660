<template>
  <v-dialog v-model="show" :max-width="900" scrollable>
    <template v-slot:activator="{ on: show, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-scale-transition hide-on-leave origin="center">
            <v-btn
              class="mr-1"
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...show }"
              v-show="selectedPatients.length > 0 || alwaysOn"
              :disabled="selectedPatients.length === 0"
            >
              <v-icon size="23">mdi-message-text-outline</v-icon>
            </v-btn>
          </v-scale-transition>
        </template>
        <span>Send Texts to Group</span>
      </v-tooltip>
    </template>

    <v-card style="height:500px; max-height: 500px; position: relative;" rounded="lg">
      <!-- Content -->
      <v-card-text class="ma-0 pa-0">
        <v-row no-gutters>
          <v-col cols="4" style="height:500px; overflow: auto">
            <v-card height="500" style="overflow: auto" tile color="background">
              <v-toolbar color="background" flat>
                <v-row no-gutters justify="start">
                  <span class="font-weight-bold text-h6">Recipients </span>
                </v-row>
              </v-toolbar>
              <v-slide-y-transition class="py-0" group>
                <v-list-item
                  style="border-bottom: 1px solid #00000008"
                  v-for="(item, index) in selectedPatients"
                  :key="index"
                >
                  <v-list-item-icon class="mr-2">
                    <div v-if="item.done">
                      <template v-if="item.success">
                        <v-icon color="green">mdi-check-circle</v-icon>
                      </template>

                      <template v-if="!item.success">
                        <v-icon color="red">mdi-close-circle</v-icon>
                      </template>
                    </div>
                    <div v-else>
                      <v-icon v-if="(!item.smsPhone || item.activeConversation) && !item.sending" color="red"
                        >mdi-close-circle-outline</v-icon
                      >

                      <template v-if="item.smsPhone && !item.activeConversation && !item.sending">
                        <v-icon color="green">mdi-checkbox-blank-circle-outline</v-icon>
                      </template>

                      <template v-if="item.sending">
                        <v-progress-circular indeterminate size="18" width="2" color="primary"> </v-progress-circular>
                      </template>
                    </div>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="font-weight-regular text-body-2 d-block text-truncate">
                        {{ item.firstName }}
                        <span class="font-weight-bold text-body-2">{{ item.lastName }}</span>
                        <span class="text--secondary ml-1" v-if="item.firstName">{{ item.ownerPatientID }}</span>
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-row
                        class="text-truncate text-body-2"
                        v-if="item.smsPhone && !item.activeConversation"
                        no-gutters
                      >
                        <span v-if="item.smsPhone && !item.activeConversation"> {{ item.smsPhone }}</span>
                      </v-row>

                      <v-row v-else-if="!item.smsPhone" no-gutters>
                        <span class="text-body-2" style="font-weight: 700; color: red">Missing Phone</span>
                      </v-row>
                      <v-row v-else-if="item.activeConversation && !item.success" no-gutters>
                        <span class="text-body-3" style="font-weight: 700; color: red">Active Conversation</span>
                      </v-row>

                      <v-row v-else-if="item.activeConversation && item.success" no-gutters>
                        <span class="text-body-3" style="font-weight: 700; color: green">Message Sent</span>
                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-slide-y-transition>
            </v-card>
          </v-col>

          <v-col>
            <v-card v-if="false" height="65" color="transparent" flat>
              <v-row no-gutters justify="start">
                <span class="font-weight-bold text-h6"> </span>
              </v-row>
              <v-row
                align="center"
                no-gutters
                class="font-weight-bold text-h6 fill-height px-4"
                justify="space-between"
              >
                <span style="opacity: 0.9"> Message </span>
                <span class="font-weight-light text-body-2" style="opacity: 0.9" v-if="!sendingTexts">
                  {{ actualRecipients.length }} Recipient<span v-if="actualRecipients.length !== 1">s</span>
                </span>
                <span class="font-weight-medium primary--text text-body-2" style="opacity: 0.9" v-if="sendingTexts">
                  {{ sendingMessageStatusMessage }}</span
                >
              </v-row>
            </v-card>

            <v-card flat tile>
              <v-toolbar color="white" flat>
                <v-row no-gutters justify="start" align="center">
                  <span class="font-weight-bold text-h6">Message </span>
                  <v-spacer />
                  <span class="font-weight-regular text-body-2 primary--text" style="opacity: 0.9" v-if="!sendingTexts">
                    {{ actualRecipients.length }} Eligible Recipient<span v-if="actualRecipients.length !== 1">s</span>
                  </span>
                  <span class="font-weight-medium primary--text text-body-2" style="opacity: 0.9" v-if="sendingTexts">
                    {{ sendingMessageStatusMessage }}</span
                  >
                </v-row>
              </v-toolbar>
              <v-progress-linear
                absolute
                background-color="white"
                height="2"
                :value="(textCounter / actualRecipients.length) * 100"
                :indeterminate="textCounter === 0 && sendingTexts"
              />

              <v-row class="pt-3 px-4" no-gutters>
                <v-card outlined rounded="lg" flat height="40">
                  <v-row align="center" no-gutters>
                    <v-col>
                      <v-select
                        height="30"
                        :items="templates"
                        background-color="transparent"
                        item-text="title"
                        dense
                        hide-details
                        solo
                        flat
                        placeholder="Templates"
                        v-model="selectedTemplate"
                        return-object
                        full-width
                        prepend-inner-icon="mdi-star-outline"
                        clearable
                      >
                      </v-select>
                    </v-col>
                    <v-divider vertical />
                    <v-btn tile text small @click="insertFirstNameAtCursor()" depressed height="40"
                      ><v-icon size="20" left>mdi-code-braces</v-icon>pt f. name</v-btn
                    >
                    <v-divider class="mx-0 px-0" vertical />
                    <v-btn tile small height="40" @click="insertVitalsListAtCursor()" depressed text
                      ><v-icon size="20" left>mdi-code-braces</v-icon>pt vitals</v-btn
                    >
                    <v-divider class="mx-0 px-0" vertical />

                    <v-dialog v-model="previewDialog" width="320" ref="previewDialog">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :disabled="exampleOutput === null"
                          tile
                          small
                          height="40"
                          depressed
                          text
                          ><v-icon size="20" left>mdi-cellphone-message</v-icon>Preview</v-btn
                        >
                      </template>

                      <v-card>
                        <v-toolbar class="font-weight-bold text-h6 text--secondary" flat>
                          Message Preview
                        </v-toolbar>
                        <v-row no-gutters class="mx-3 pt-1 pb-6">
                          <v-card style="border-radius: 15px;" class="px-3 py-2" color="#00D549" flat>
                            <span class="white--text">{{ exampleOutput }}</span>
                          </v-card>
                        </v-row>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn depressed @click="previewDialog = false">
                            close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-card>
              </v-row>
              <v-card flat height="316">
                <v-textarea
                  color="green"
                  ref="textarea"
                  class="font-weight-regular text-body-1 px-4"
                  no-resize
                  v-model="textMessage"
                  counter
                  placeholder="Enter your message here..."
                  flat
                  hide-details
                  rows="11"
                  height="316"
                >
                </v-textarea>
              </v-card>

              <v-card v-if="false" class="px-2" style="position: relative" flat tile color="white">
                <v-fade-transition>
                  <v-overlay v-show="sendingTexts || allDoneSending" opacity="0.5" absolute color="white">
                    <span v-if="!allDoneSending" class="primary--text text-h6">Sending Messages</span>
                    <span v-if="allDoneSending" class="primary--text text-h6">Messages Sent</span>
                  </v-overlay>
                </v-fade-transition>

                <v-card v-if="false" height="120" flat tile class="px-4 pb-4">
                  <v-row
                    v-show="exampleOutput !== null && !sendingTexts && !allDoneSending"
                    no-gutters
                    class="px-1 text-caption"
                  >
                    Example:
                  </v-row>
                  <v-card
                    v-show="exampleOutput !== null && !sendingTexts && !allDoneSending"
                    class="mt-1"
                    flat
                    rounded="lg"
                    color="grey lighten-4"
                    height="80"
                    style="overflow: auto"
                  >
                    <v-card-text class="text-body-2 pa-1 px-2">
                      {{ exampleOutput }}
                    </v-card-text>
                  </v-card>
                </v-card>
              </v-card>
            </v-card>
            <v-card flat tile height="64">
              <v-row no-gutters class="fill-height px-4" align="center">
                <v-btn @click="cancel" depressed>cancel</v-btn>
                <v-spacer />
                <v-dialog v-model="saveTemplateDialog" width="500" ref="saveTemplateDialog">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      outlined
                      v-show="showSaveAsTemplate && user.partnerRoles && user.partnerRoles.admin"
                      @click="savedTemplateTitle = null"
                    >
                      Save as template
                    </v-btn>
                  </template>

                  <v-card>
                    <v-row
                      align="center"
                      no-gutters
                      justify="space-between"
                      class="font-weight-bold text-h6 px-2"
                      style="height:60px;"
                    >
                      <v-text-field
                        ref="templateNameText"
                        v-model="savedTemplateTitle"
                        style="font-size:20px;"
                        solo
                        flat
                        hide-details
                        placeholder="Template Name"
                      >
                      </v-text-field>
                    </v-row>

                    <v-textarea
                      class="font-weight-light text-body-1 px-2"
                      no-resize
                      v-model="textMessage"
                      hide-details
                      placeholder="Message"
                      solo
                      flat
                      height="150"
                    >
                    </v-textarea>

                    <v-card-actions>
                      <v-btn depressed @click="saveTemplateDialog = false">
                        cancel
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn depressed color="primary" @click="saveTemplateWithTitle()">
                        Save Template
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-btn
                  :disabled="actualRecipients.length === 0 || textMessage === '' || textMessage === null"
                  @click="actionClick"
                  depressed
                  color="primary"
                >
                  <v-scale-transition hide-on-leave origin="center">
                    <v-progress-circular
                      indeterminate
                      size="20"
                      width="3"
                      color="white"
                      v-show="sendingTexts && !allDoneSending"
                      class="mr-3"
                    />
                  </v-scale-transition>

                  <v-scale-transition hide-on-leave origin="center">
                    <span v-show="!sendingTexts && !allDoneSending && actualRecipients.length > 0"
                      >Send {{ actualRecipients.length }} Message<span v-if="actualRecipients.length > 1">s</span></span
                    >
                  </v-scale-transition>
                  <v-scale-transition hide-on-leave origin="center">
                    <span v-show="!sendingTexts && !allDoneSending && actualRecipients.length === 0"
                      >No Eligible Recipients</span
                    >
                  </v-scale-transition>
                  <v-scale-transition hide-on-leave origin="center">
                    <span v-show="allDoneSending">Done</span>
                  </v-scale-transition>
                  <v-scale-transition hide-on-leave origin="center">
                    <span v-show="sendingMessageStatusMessage && !allDoneSending">Sending</span>
                  </v-scale-transition>
                </v-btn>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import HelperMixin from '@/core/mixins/HelperMixin'
import { mapState, mapActions } from 'vuex'
import bus from '@/core/helpers/bus'

export default {
  name: 'OneConfirmDialog',
  mixins: [DialogMixin, HelperMixin],
  components: {},
  data() {
    return {
      show: false,
      group: [],
      tableKey: 0,
      textMessage: '',
      exampleOutput: null,
      allDoneSending: false,
      textCounter: 0,
      sendingTexts: false,
      messageCardHeight: 500,
      saveTemplateDialog: false,
      savedTemplateTitle: null,
      sendingMessageStatusMessage: null,
      selectedTemplate: null,
      previewDialog: false,
    }
  },
  props: {
    selectedPatients: {
      type: Array,
      default: null,
    },
    alwaysOn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions('dashboard', ['getDataForCSV', 'prepareEventData']),
    ...mapActions('taskboard', ['getEnrolledPatients']),
    ...mapActions('masstext', [
      'sendMassTextToPatient',
      'applyTextExpansionToString',
      'saveATextCampaignTemplate',
      'deleteACampaignTemplate',
    ]),
    ...mapActions('auth', ['dismissInactivity']),
    actionClick() {
      if (this.allDoneSending) {
        this.cancel()
      } else if (!this.sendingTexts) {
        this.sendTheMessages()
      }
    },
    insertTemplate(template) {
      this.textMessage = template.content
    },
    insertFirstNameAtCursor() {
      this.execInsertText('{patient_first_name}')
    },
    insertVitalsListAtCursor() {
      this.execInsertText('{vitals_list}')
    },
    execInsertText(insertText) {
      if (!insertText.length) return

      const textarea = this.$refs.textarea.$refs.input
      const sentence = textarea.value

      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      let positionEnd = pos

      if (textarea.selectionEnd && textarea.selectionEnd !== pos) {
        // you've chosen to replace an entire selection with a text expansion prompt
        // remove the entire word that was selected and replace it with the correct text
        positionEnd = textarea.selectionEnd
      }

      let before = sentence.substr(0, pos)
      let after = sentence.substr(positionEnd, len)

      // add a space if there isnt one?

      if (before.length && before.slice(-1) !== null && before.slice(-1) !== ' ') {
        before = before + ' '
      }

      if (
        after.charAt(0).length &&
        after.charAt(0) !== ' ' &&
        after.charAt(0) !== '!' &&
        after.charAt(0) !== '.' &&
        after.charAt(0) !== '?' &&
        after.charAt(0) !== ','
      ) {
        after = ' ' + after
      }

      this.textMessage = before + insertText + after
      let vm = this
      this.$nextTick().then(() => {
        textarea.selectionStart = before.length + insertText.length
        textarea.selectionEnd = before.length + insertText.length
        vm.$refs.textarea.focus()
      })
    },
    sendTheMessages() {
      // for each patient. get the latest conversation
      let vm = this
      function sendConversationMessage() {
        vm.sendingTexts = true
        if (vm.actualRecipients.length > vm.textCounter && vm.show) {
          vm.actualRecipients[vm.textCounter].sending = true
          let currentMessage = vm.textCounter + 1
          vm.sendingMessageStatusMessage = currentMessage + ' of ' + vm.actualRecipients.length

          vm.applyTextExpansionToString({
            patient: vm.actualRecipients[vm.textCounter],
            message: vm.textMessage,
          }).then(message => {
            vm.sendMassTextToPatient({
              patient: vm.actualRecipients[vm.textCounter],
              message: message,
            }).then(data => {
              if (data.status === 200) {
                vm.actualRecipients[vm.textCounter].sending = false
                vm.actualRecipients[vm.textCounter].done = true
                vm.actualRecipients[vm.textCounter].success = true

                vm.actualRecipients[vm.textCounter].retired = true
                //vm.actualRecipients[vm.textCounter].retired = true

                // dismiss any inactivity alerts for this patient
                const found = vm.enrolledPatients.find(
                  patient => patient._id === vm.actualRecipients[vm.textCounter]._id
                )

                if (found) {
                  found.activeConversation = true
                }
              } else {
                vm.actualRecipients[vm.textCounter].sending = false
                vm.actualRecipients[vm.textCounter].done = true
                vm.actualRecipients[vm.textCounter].success = false
                let boom = vm
                setTimeout(function() {
                  boom.actualRecipients[boom.textCounter].retired = true
                }, 300)
              }
              vm.textCounter = vm.textCounter + 1
              sendConversationMessage()
            })
          })
        } else {
          vm.sendingTexts = false

          vm.allDoneSending = true
          // refresh the dashboard?
          vm.getEnrolledPatients()
        }
      }
      if (this.textMessage && this.textMessage.length > 0) {
        sendConversationMessage()
      } else {
        bus.$emit('toast', { type: 'error', text: 'No message entered' })
      }
    },
    saveTemplateWithTitle() {
      if (this.savedTemplateTitle !== null && this.savedTemplateTitle !== '') {
        let template = {
          title: this.savedTemplateTitle,
          content: this.textMessage,
        }
        this.saveATextCampaignTemplate(template)
        this.saveTemplateDialog = false
        bus.$emit('toast', { type: 'success', text: 'Template saved' })
      }
    },
    permanentlyDeleteATemplate(template) {
      this.deleteACampaignTemplate(template)
    },
  },
  watch: {
    selectedTemplate(val) {
      if (val) {
        console.l
        this.insertTemplate(val)
      }
    },
    showSaveAsTemplate(val) {
      if (val) {
        this.selectedTemplate = null
      }
    },
    show(val) {
      this.selectedTemplate = null
      this.textCounter = 0
      this.sendingTexts = false
      this.sendingMessageStatusMessage = null
      this.textMessage = ''
      this.allDoneSending = false
      if (val) {
        this.actualRecipients.forEach(recipient => {
          recipient.done = false
          recipient.success = false
          recipient.retired = false
          recipient.sending = false
        })
      }
    },
    saveTemplateDialog() {
      let vm = this

      this.$nextTick().then(() => {
        vm.$refs.templateNameText.$refs.input.focus()
      })
    },
    allDoneSending(val) {
      if (val) {
        bus.$emit('toast', { type: 'success', text: 'Messages Sent' })
      }
    },
    textMessage(val) {
      if (val && this.actualRecipients[0]) {
        this.applyTextExpansionToString({
          patient: this.actualRecipients[0],
          message: val,
        }).then(data => {
          if (data !== val) {
            this.exampleOutput = data
          } else {
            this.exampleOutput = null
          }
        })
      } else {
        this.exampleOutput = null
      }
    },
  },
  computed: {
    ...mapState('patient', ['patient']),
    ...mapState('masstext', ['savedTextTemplates']),
    ...mapState('auth', ['user', 'configuration', 'appTheme']),
    ...mapState('taskboard', ['enrolledPatients']),
    showSaveAsTemplate() {
      // return if text message is different than the templates
      if (this.textMessage !== '' && !this.sendingTexts && !this.allDoneSending) {
        let returnFlag = true

        this.templates.forEach(template => {
          if (template.content === this.textMessage) {
            returnFlag = false
          }
        })
        return returnFlag
      }
      return false
    },
    showBuilding() {
      return this.options.unit && this.options.building
    },
    templates() {
      let templates = this.savedTextTemplates
      //templates.push({title: 'Reminder Template', content: 'Hello {patient_first_name}, this is a quick reminder to measure your {vitals_list} today.'})
      return templates
    },
    actualRecipients() {
      return this.selectedPatients.filter(function(e) {
        return e.smsPhone != null && !e.activeConversation
      })
    },
    fullSelected() {
      if (this.group) {
        return this.group
      }
      return []
    },
    retiredPatients() {
      return this.selectedPatients.filter(function(e) {
        return e.retired
      })
    },
  },
}
</script>
