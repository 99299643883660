<template>
  <v-dialog v-model="show" max-width="450" scrollable hide-on-leave persistent>
    <template v-slot:activator="{ on: show, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-scale-transition hide-on-leave origin="center">
            <v-btn
              class="mr-1"
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...show }"
              v-show="selectedPatients.length > 0 || alwaysOn"
              :disabled="selectedPatients.length === 0"
            >
              <v-icon size="23">mdi-tag-outline</v-icon>
            </v-btn>
          </v-scale-transition>
        </template>
        <span>Add or Remove Tags</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar flat>
        <v-row no-gutters justify="start">
          <span
            class="font-weight-bold text-h6"
            v-if="selectedPatients.length > 0 && selectedPatients[0].firstName && selectedPatients[0].lastName"
            >{{ selectedPatients[0].firstName }} {{ selectedPatients[0].lastName }}
          </span>
          <span
            class="font-weight-bold text-h6"
            v-if="selectedPatients.length > 1 && selectedPatients[1].firstName && selectedPatients[1].lastName"
            >, {{ selectedPatients[1].firstName }} {{ selectedPatients[1].lastName }}
          </span>
          <span class="font-weight-bold text-h6" v-if="selectedPatients.length > 2">
            , + {{ selectedPatients.length - 2 }} more
          </span>
        </v-row>
      </v-toolbar>
      <v-card-text class="pa-4 pt-0">
        <v-row no-gutters>
          <v-chip
            class="mr-2 mb-2"
            label
            v-for="(tag, index) in tagCloud"
            :key="index + tag"
            :color="tag.color"
            @click="handleTagClick(tag)"
          >
            {{ tag.text }}</v-chip
          >
          <v-text-field
            style="max-width:170px; height:20px; margin-top:-3px;"
            dense
            class="mb-3"
            ref="tagBox"
            v-model="newTag"
            solo
            flat
            hide-details
            placeholder="Create new tag"
            @keyup.enter.stop.prevent="submitNewTag"
            @keyup.188.stop.prevent="submitNewTag"
            prepend-inner-icon="mdi-tag-plus-outline"
            color="primary"
          >
          </v-text-field>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end" no-gutters class="pb-1">
          <v-btn
            v-if="false"
            class="mr-2"
            text
            color="red"
            v-show="options.patient.tags.length > 1"
            depressed
            @click="clearChartTags()"
            >Remove All Tags</v-btn
          >
          <v-btn depressed @click="cancel">Done</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import { mapActions, mapState, mapMutations } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import { mask } from 'vue-the-mask'
import bus from '@/core/helpers/bus'
//import get from 'lodash/get'

export default {
  name: 'PAtientTagManagerDialog',
  mixins: [DialogMixin, HelperMixin],
  components: {},
  directives: {
    mask,
  },
  props: {
    selectedPatients: {
      type: Array,
      default: null,
    },
    alwaysOn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newTag: null,
      saveOnSubmit: false,
    }
  },
  methods: {
    ...mapActions('patient', ['updatePatientTagsV2']),
    ...mapMutations('taskboard', ['addASinglePatientTag']),
    updateLocalPatient() {
      let found = this.enrolledPatients.find(patient => patient._id === this.options.patient._id)

      console.log(found)
      console.log(this.options.patient)
      console.log('updated local patient')
      if (found) {
        found.tags = this.options.patient.tags
      }
      // updateDashboard Key?
      bus.$emit('updateDataTableKeys')
    },
    submitNewTag() {
      this.handleTagClick({ text: this.newTag.replace(',', ''), partial: false, full: false })

      // if (!this.options.patient.tags.includes(this.newTag.replace(',', ''))) {
      //   this.options.patient.tags.push(this.newTag.replace(',', ''))
      //   //this.submitChanges()
      // }

      // // do we need to add this tag to the local patient tags list?
      // let found = this.patientTags.find(tag => tag.text === this.newTag)

      // if (!found) {
      //   this.addASinglePatientTag({ text: this.newTag, count: 1 })
      // }
      //this.updateLocalPatient()
      this.newTag = null
    },
    clearChartTags() {
      this.options.patient.tags = []
      //this.submitChanges()
      //this.updateLocalPatient()
    },
    handleTagClick(tag) {
      console.log(tag)
      if (tag.full) {
        // go and give every patient the tag
        this.selectedPatients.forEach(patient => {
          if (patient.tags.includes(tag.text)) {
            let index = patient.tags.indexOf(tag.text)
            if (index > -1) {
              patient.tags.splice(index, 1)
            }
          }
        })
      } else {
        // go and give every patient the tag
        this.selectedPatients.forEach(patient => {
          if (!patient.tags.includes(tag.text)) {
            patient.tags.push(tag.text)
          }
        })
      }
      // for every patient that doesnt have this tag,
      // if (this.options.patient.tags.includes(tag.text)) {
      //   let index = this.options.patient.tags.indexOf(tag.text)
      //   if (index > -1) {
      //     this.options.patient.tags.splice(index, 1)
      //   }
      // } else {
      //   this.options.patient.tags.push(tag.text)
      // }
      //this.submitChanges()
      //this.updateLocalPatient()
      //console.log('handlle tag stuff')
    },
    submitChanges() {
      if (this.newTag !== null) {
        this.handleTagClick({ text: this.newTag.replace(',', ''), partial: false, full: false })
        this.newTag = null
      }
      this.selectedPatients.forEach(patient => {
        this.updatePatientTagsV2({
          patientID: patient._id,
          tagList: patient.tags,
        }).catch(error => {
          console.log(error)
          bus.$emit('toast', { type: 'error', text: 'Error Tagging Chart' })
        })
      })
    },
    onOpen() {},
    onClose() {
      this.submitChanges()
    },
    cancelOption() {
      this.cancel()
    },
  },
  computed: {
    ...mapState('auth', ['partner', 'user']),
    ...mapState('populationview', ['enrolledPatients']),
    patientTags() {
      let arrayOfArrayOfTags = this.enrolledPatients.map(a => a.tags)

      let tags = []
      arrayOfArrayOfTags.forEach(patientTags => {
        patientTags.forEach(tag => {
          if (!tags.includes(tag)) {
            tags.push(tag)
          }
        })
      })
      return tags
    },
    tagCloud() {
      // generate a list that has three settings for each tag. 1: everybody has that tag, blue 2: some people have the tag, outlined 3: nobody has the tag. gray
      let tagsToReturn = []
      this.patientTags.forEach(tag => {
        let tagToBeAdded = { text: tag, partial: false, full: false, color: 'grey lighten-4' }
        let patientCountForTagChecking = 0
        this.selectedPatients.forEach(patient => {
          if (patient.tags.includes(tag)) {
            tagToBeAdded.partial = true
            patientCountForTagChecking = patientCountForTagChecking + 1
            tagToBeAdded.color = 'primary lighten-2'
          }
        })
        if (patientCountForTagChecking === this.selectedPatients.length) {
          tagToBeAdded.partial = false
          tagToBeAdded.full = true
          tagToBeAdded.color = 'primary'
        }

        tagsToReturn.push(tagToBeAdded)
      })
      return tagsToReturn
    },
  },
  mounted() {
    this.$on('open', this.onOpen)
    this.$on('close', this.onClose)
  },
}
</script>
