<template>
  <v-col :cols="cols">
    <v-row no-gutters>
      <v-card rounded="lg" class="mr-2 mb-2 flex pa-3" min-height="150" color="grey lighten-4" flat>
        <v-row no-gutters align="start" justify="start">
          <span :style="style" id="value" class="text-h4 font-weight-light">{{ value }} </span>
          <v-spacer />
          <v-col v-if="comparisonTotal">
            <v-row no-gutters align="center" justify="end">
              <v-progress-circular
                class="mr-1"
                size="16"
                color="primary"
                :value="Math.round((value / comparisonTotal) * 100 * 10) / 10"
                width="3"
              />
              <span :style="style" class="font-weight-light "
                >{{ Math.round((finalValue / comparisonTotal) * 100 * 10) / 10 }}%
              </span>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-2" no-gutters align="center" justify="start">
          <span id="value" class="text-body-2 font-weight-light text--secondary">
            <span class="font-weight-medium mr-1">{{ shortTitle }}:</span>{{ title }}
          </span>
        </v-row>
      </v-card>
    </v-row>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      value: 0,
      style: 'opacity:1',
    }
  },
  props: {
    finalValue: {
      type: Number,
      default: 0,
    },
    comparisonTotal: null,
    shortTitle: {
      type: String,
      default: 'Count',
    },
    title: {
      type: String,
      default: 'Total Patient Count',
    },
  },
  watch: {
    finalValue(val) {
      this.animateValue(parseInt(document.getElementById('value').innerHTML), val, 500)
    },
  },
  computed: {
    cols() {
      if (this.$vuetify.breakpoint.md) {
        return 3
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 2
      }

      return 4
    },
  },
  methods: {
    animateValue(start, end, duration) {
      let startTimestamp = null
      const step = timestamp => {
        if (!startTimestamp) startTimestamp = timestamp
        const progress = Math.min((timestamp - startTimestamp) / duration, 1)
        this.value = Math.floor(progress * (end - start) + start)
        if (progress < 1) {
          this.style = 'opacity: 0.3'
          window.requestAnimationFrame(step)
        } else {
          this.style = 'opacity: 1'
        }
      }
      window.requestAnimationFrame(step)
    },
  },
  mounted() {
    this.animateValue(0, this.finalValue, 500)
  },
}
</script>

<style></style>
