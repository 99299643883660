<template>
  <v-card color="transparent" tile flat>
    <v-fade-transition>
      <v-progress-linear
        absolute
        query
        :indeterminate="patientLoadingProgress === 0"
        v-show="patientLoadingProgress < 100 && refreshingTaskboard"
        v-model="patientLoadingProgress"
      />
    </v-fade-transition>
    <portal to="appBarContentExtension">
      <v-card class="flex" rounded="lg" outlined>
        <v-row no-gutters>
          <v-col>
            <v-select
              v-model="selectedConditions"
              multiple
              placeholder="Condition"
              :items="patientConditions"
              single-line
              background-color="transparent"
              hide-details
              dense
              solo
              flat
            >
              <template v-slot:prepend-inner>
                <v-icon class="mr-1" size="18">mdi-bandage</v-icon>
              </template>
              <template v-slot:selection="{ index }">
                <span v-if="index === 0" class="primary--text text-truncate d-block">
                  Condition ({{ selectedConditions.length }})
                </span>
              </template>
            </v-select>
          </v-col>
          <v-divider vertical />
          <v-col>
            <v-select
              v-model="selectedTags"
              multiple
              placeholder="Tag"
              :items="patientTags"
              single-line
              background-color="transparent"
              hide-details
              dense
              solo
              flat
            >
              <template v-slot:prepend-inner>
                <v-icon class="mr-1" size="18">mdi-tag</v-icon>
              </template>
              <template v-slot:selection="{ index }">
                <span v-if="index === 0" class="primary--text text-truncate d-block">
                  Tag ({{ selectedTags.length }})
                </span>
              </template>
            </v-select>
          </v-col>
          <v-divider vertical />
          <v-col>
            <v-select
              v-model="selectedAlertFilter"
              placeholder="Alert"
              :items="alertFilterOptions"
              single-line
              background-color="transparent"
              hide-details
              dense
              solo
              flat
              return-object
            >
              <template v-slot:prepend-inner>
                <v-icon class="mr-1" size="18">mdi-alert-circle</v-icon>
              </template>
            </v-select>
          </v-col>
          <v-divider vertical />
          <v-col>
            <v-select
              v-model="selectedZips"
              multiple
              placeholder="Zip Code"
              :items="patientZips"
              single-line
              background-color="transparent"
              hide-details
              dense
              solo
              flat
            >
              <template v-slot:prepend-inner>
                <v-icon class="mr-1" size="18">mdi-map-marker</v-icon>
              </template>
              <template v-slot:selection="{ index }">
                <span v-if="index === 0" class="primary--text text-truncate d-block">
                  Zip Code ({{ selectedZips.length }})
                </span>
              </template>
            </v-select>
          </v-col>
          <v-divider vertical />
          <v-col>
            <v-select
              v-model="selectedActivityOption"
              placeholder="Activity"
              :items="activityOptions"
              single-line
              background-color="transparent"
              hide-details
              dense
              solo
              flat
              return-object
            >
              <v-icon v-if="!selectedActivityOption" size="18" class="mr-1" slot="prepend-inner"
                >mdi-calendar-multiselect</v-icon
              >

              <template v-slot:item="data">
                <v-list-item v-bind="data.attrs" v-on="data.on">
                  <v-list-item-icon class="mr-1 pr-1">
                    <v-icon :color="data.item.iconColor" left>{{ data.item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-row style="max-width: 400px" class="flex-nowrap" no-gutters>
                      <span class="text-truncate">{{ data.item.text }}</span>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:prepend-inner>
                <v-icon class="mr-1" size="18">mdi-calendar-multiselect</v-icon>
              </template>
            </v-select>
          </v-col>
          <v-divider vertical />
          <v-col>
            <v-select
              v-model="selectedQHPOption"
              placeholder="Time"
              :items="qhpOptions"
              single-line
              background-color="transparent"
              hide-details
              dense
              solo
              return-object
              flat
            >
              <template v-slot:prepend-inner>
                <v-icon class="mr-1" size="18">mdi-clock</v-icon>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-card>
    </portal>
    <portal to="appBarContentLeft">
      <v-row class="flex-nowrap" align="center" justify="space-between" no-gutters>
        <span v-if="partner" class="text-no-wrap font-weight-light text-h5 mr-4">
          {{ partner.name }}
        </span>
      </v-row>
    </portal>

    <portal to="appBarContentRight">
      <v-row class="flex-nowrap" align="center" justify="space-between" no-gutters>
        <advanced-search-menu />
        <v-text-field
          style="width:10px"
          v-model="searchInput"
          placeholder="Patient Search"
          dense
          hide-details
          solo
          flat
          background-color="grey lighten-3"
          clearable
        >
        </v-text-field>
      </v-row>
    </portal>
    <v-slide-y-reverse-transition>
      <v-container v-show="enrolledPatients.length > 0" class="pa-4" fluid>
        <v-row align="start" no-gutters>
          <v-col>
            <org-stats-bar
              @clearFilters="removeAllFilters()"
              @priority="priorityFilter = true"
              @activeToday="selectedActivityOption = activityOptions[0]"
              @activeSeven="selectedActivityOption = activityOptions[4]"
              @newPatients="newPatientFilter = true"
              @alert="selectedAlertFilter = alertFilterOptions[0]"
              @condition="applyConditionFilter"
              class="mb-4"
              :patientConditions="patientConditions"
              :filteredArray="filteredPatients"
            />
          </v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                height="40"
                width="40"
                class="mx-1"
                @click=";(showMap = !showMap), (showStats = false)"
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon medium color="primary" v-if="showMap">mdi-map-marker</v-icon>
                <v-icon medium v-else>mdi-map-marker</v-icon>
              </v-btn>
            </template>
            Population Summary
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                height="40"
                width="40"
                class="mx-1"
                @click=";(showStats = !showStats), (showMap = false)"
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon medium color="primary" v-if="showStats">mdi-chart-donut</v-icon>
                <v-icon medium v-else>mdi-chart-donut</v-icon>
              </v-btn>
            </template>
            Population Summary
          </v-tooltip>
        </v-row>
        <v-row v-show="filterApplied" class="mb-4" no-gutters>
          <v-chip dark color="grey darken-2" class="mr-1" label close @click:close="removeAllFilters()"
            >Filters Applied:</v-chip
          >

          <v-chip v-show="priorityFilter" color="white" class="mr-1" label close @click:close="priorityFilter = false"
            ><v-icon color="primary" left small>mdi-circle</v-icon>High Priority</v-chip
          >

          <v-chip
            v-show="newPatientFilter"
            color="white"
            class="mr-1"
            label
            close
            @click:close="newPatientFilter = false"
            ><v-icon color="primary" left small>mdi-account-plus</v-icon>New Patients</v-chip
          >

          <v-chip
            color="white"
            class="mr-1"
            label
            close
            @click:close="removeSpecificConditionFilter(item)"
            v-for="item in selectedConditions"
            :key="item"
            ><v-icon color="grey darken-1" left small>mdi-bandage</v-icon>{{ item }}</v-chip
          >
          <v-chip
            class="mr-1"
            color="white"
            label
            close
            @click:close="removeSpecificTagFilter(item)"
            v-for="item in selectedTags"
            :key="item"
            ><v-icon color="grey darken-1" left small>mdi-tag</v-icon>{{ item }}</v-chip
          >

          <v-chip v-if="searchInput !== null" class="mr-1" color="white" label close @click:close="searchInput = null"
            ><v-icon color="grey darken-1" left small>mdi-magnify</v-icon>{{ '"' + searchInput + '"' }}</v-chip
          >
          <v-chip
            class="mr-1"
            color="white"
            label
            close
            @click:close="selectedAlertFilter = null"
            v-if="selectedAlertFilter"
            ><v-icon color="grey darken-1" left small>mdi-alert-circle</v-icon>{{ selectedAlertFilter.text }}</v-chip
          >

          <v-chip
            class="mr-1"
            color="white"
            label
            close
            @click:close="removeSpecificZipFilter(item)"
            v-for="item in selectedZips"
            :key="item"
            ><v-icon color="grey darken-1" left small>mdi-map-marker</v-icon>{{ item }}</v-chip
          >

          <v-chip
            class="mr-1"
            color="white"
            label
            close
            @click:close="selectedActivityOption = null"
            v-if="selectedActivityOption !== null"
            ><v-icon color="grey darken-1" left small>mdi-calendar-multiselect</v-icon
            >{{ selectedActivityOption.text }}</v-chip
          >

          <v-chip
            class="mr-1"
            color="white"
            label
            close
            @click:close="selectedQHPOption = null"
            v-if="selectedQHPOption !== null"
            ><v-icon color="grey darken-1" left small>mdi-clock</v-icon>{{ selectedQHPOption.text }}</v-chip
          >
        </v-row>

        <v-row v-if="showMap" align="center" no-gutters class="pb-3 flex">
          <v-col cols="12">
            <v-card flat outlined rounded="lg" style="overflow: hidden">
              <map-view
                :filteredArray="filteredPatients"
                @zipCodeClick="zipCodeSelected"
                @hoverEvent="hoverOverMapPoint"
                @hoverCancel="cancelHoverOverMapPoint"
              />
            </v-card>
          </v-col>
        </v-row>

        <!--dashboard panel-->
        <v-row v-if="showStats" align="center" no-gutters class="pb-3 flex">
          <v-col cols="12">
            <v-card flat color="transparent" rounded="lg">
              <admin-dashboard :filteredArray="filteredPatients" />
            </v-card>
          </v-col>
        </v-row>

        <tagged-notes-section v-show="!filterApplied && !selectedPatients.length > 0" class="mb-4" />

        <unseen-alert-section v-show="!filterApplied && !selectedPatients.length > 0" class="mb-4" />

        <high-priority-patient-section v-show="!filterApplied && !selectedPatients.length > 0" class="mb-4" />

        <portal-target name="mainHeader"> </portal-target>
        <v-card flat rounded="lg" outlined style="overflow: hidden">
          <v-data-table
            ref="dataTable"
            fixed-header
            v-model="selectedPatients"
            :items="filteredPatients"
            item-key="_id"
            show-select
            class="elevation-0"
            :items-per-page="30"
            @click:row="handleClick"
            :options.sync="options"
            @page-count="pageCount = $event"
            :page.sync="page"
            hide-default-footer
            v-bind:height="selectedPatients.length > 0 ? patientTableHeight : null"
          >
            <template v-slot:item.data-table-select="{ isSelected, select, item }">
              <v-row align="center" class="fill-height flex-nowrap row-pointer" style="position: relative" no-gutters>
                <template>
                  <div
                    v-if="item.alert"
                    style="position: absolute; top: 0px; left: -18px; width:7px; height:100%; background-color: yellow"
                  ></div>
                  <div
                    v-else-if="item.tookReadingToday"
                    style="position: absolute; top: 0px; left: -18px; width:7px; height:100%; background-color: #4CB051"
                  ></div>
                  <div
                    v-else-if="item.highPriority"
                    style="position: absolute; top: 0px; left: -18px; width:7px; height:100%; background-color: #1A76F2"
                  ></div>
                  <div
                    v-else-if="item.tookReadingLastSeven"
                    style="position: absolute; top: 0px; left: -18px; width:7px; height:100%; background-color: #81C784"
                  ></div>
                  <div
                    v-else-if="item.newPatient"
                    style="position: absolute; top: 0px; left: -18px; width:7px; height:100%; background-color: #FF9800"
                  ></div>
                  <div
                    v-else
                    style="position: absolute; top: 0px; left: -18px; width:7px; height:100%; background-color: #f5f5f5"
                  ></div>
                </template>

                <v-simple-checkbox
                  color="primary"
                  v-ripple
                  :value="isSelected"
                  @input="select($event)"
                  class="mr-2"
                ></v-simple-checkbox>
                <patient-info-cell :item="item" />
                <v-col>
                  <v-row
                    align="center"
                    v-if="item.MEASUREMENT_BLOOD_PRESSURE"
                    class="flex-nowrap text-body-2"
                    no-gutters
                  >
                    <span style="max-width: 20vw" class="text-truncate">
                      <v-icon class="mr-1" small :color="metricList['MEASUREMENT_BLOOD_PRESSURE'].iconColor">{{
                        metricList['MEASUREMENT_BLOOD_PRESSURE'].icon
                      }}</v-icon
                      ><span class="font-weight-bold mr-1">{{ item.MEASUREMENT_BLOOD_PRESSURE.value }}</span>
                    </span>
                  </v-row>
                  <v-row no-gutters style="margin-top: -4px;" v-if="item.MEASUREMENT_BLOOD_PRESSURE" class="pl-5">
                    <span style="max-width: 10vw" class="text--disabled text-caption text-truncate">
                      {{ relativeTime(item.MEASUREMENT_BLOOD_PRESSURE.timestamp) }}
                    </span>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row align="center" v-if="item.MEASUREMENT_PULSE" class="flex-nowrap text-body-2" no-gutters>
                    <span style="max-width: 20vw" class="text-truncate">
                      <v-icon class="mr-1" small :color="metricList['MEASUREMENT_PULSE'].iconColor">{{
                        metricList['MEASUREMENT_PULSE'].icon
                      }}</v-icon
                      ><span class="font-weight-bold mr-1">{{ item.MEASUREMENT_PULSE.value }}</span>
                    </span>
                  </v-row>
                  <v-row no-gutters style="margin-top: -4px;" v-if="item.MEASUREMENT_PULSE" class="pl-5">
                    <span style="max-width: 10vw" class="text--disabled text-caption text-truncate">
                      {{ relativeTime(item.MEASUREMENT_PULSE.timestamp) }}
                    </span>
                  </v-row>
                </v-col>

                <v-col>
                  <v-row
                    align="center"
                    v-if="item.MEASUREMENT_BLOOD_GLUCOSE"
                    class="flex-nowrap text-body-2"
                    no-gutters
                  >
                    <span style="max-width: 20vw" class="text-truncate">
                      <v-icon class="mr-1" small :color="metricList['MEASUREMENT_BLOOD_GLUCOSE'].iconColor">{{
                        metricList['MEASUREMENT_BLOOD_GLUCOSE'].icon
                      }}</v-icon
                      ><span class="font-weight-bold mr-1">{{ item.MEASUREMENT_BLOOD_GLUCOSE.value }}</span>
                    </span>
                  </v-row>
                  <v-row no-gutters style="margin-top: -4px;" v-if="item.MEASUREMENT_BLOOD_GLUCOSE" class="pl-5">
                    <span style="max-width: 10vw" class="text--disabled text-caption text-truncate">
                      {{ relativeTime(item.MEASUREMENT_BLOOD_GLUCOSE.timestamp) }}
                    </span>
                  </v-row>
                </v-col>

                <v-col>
                  <v-row align="center" v-if="item.MEASUREMENT_BLOOD_OXYGEN" class="flex-nowrap text-body-2" no-gutters>
                    <span style="max-width: 20vw" class="text-truncate">
                      <v-icon class="mr-1" small :color="metricList['MEASUREMENT_BLOOD_OXYGEN'].iconColor">{{
                        metricList['MEASUREMENT_BLOOD_OXYGEN'].icon
                      }}</v-icon
                      ><span class="font-weight-bold mr-1">{{ item.MEASUREMENT_BLOOD_OXYGEN.value }}</span>
                    </span>
                  </v-row>
                  <v-row no-gutters style="margin-top: -4px;" v-if="item.MEASUREMENT_BLOOD_OXYGEN" class="pl-5">
                    <span style="max-width: 10vw" class="text--disabled text-caption text-truncate">
                      {{ relativeTime(item.MEASUREMENT_BLOOD_OXYGEN.timestamp) }}
                    </span>
                  </v-row>
                </v-col>

                <v-col>
                  <v-row align="center" v-if="item.MEASUREMENT_BODY_WEIGHT" class="flex-nowrap text-body-2" no-gutters>
                    <span style="max-width: 20vw" class="text-truncate">
                      <v-icon class="mr-1" small :color="metricList['MEASUREMENT_BODY_WEIGHT'].iconColor">{{
                        metricList['MEASUREMENT_BODY_WEIGHT'].icon
                      }}</v-icon
                      ><span class="font-weight-bold mr-1">{{ item.MEASUREMENT_BODY_WEIGHT.value }}</span>
                    </span>
                  </v-row>
                  <v-row no-gutters style="margin-top: -4px;" v-if="item.MEASUREMENT_BODY_WEIGHT" class="pl-5">
                    <span style="max-width: 10vw" class="text--disabled text-caption text-truncate">
                      {{ relativeTime(item.MEASUREMENT_BODY_WEIGHT.timestamp) }}
                    </span>
                  </v-row>
                </v-col>

                <v-col>
                  <v-row align="center" v-if="item.MEASUREMENT_ECG" class="flex-nowrap text-body-2" no-gutters>
                    <span style="max-width: 20vw" class="text-truncate">
                      <v-icon class="mr-1" small :color="metricList['MEASUREMENT_ECG'].iconColor">{{
                        metricList['MEASUREMENT_ECG'].icon
                      }}</v-icon
                      ><span class="font-weight-bold mr-1">{{ item.MEASUREMENT_ECG.value }}</span>
                    </span>
                  </v-row>
                  <v-row no-gutters style="margin-top: -4px;" v-if="item.MEASUREMENT_ECG" class="pl-5">
                    <span style="max-width: 10vw" class="text--disabled text-caption text-truncate">
                      {{ relativeTime(item.MEASUREMENT_ECG.timestamp) }}
                    </span>
                  </v-row>
                </v-col>

                <v-col>
                  <v-row no-gutters>
                    <span style="max-width: 10vw" class="font-weight-medium text-subtitle-2 text-truncate"
                      ><v-icon class="mr-1" small>mdi-clock</v-icon>{{ msToTime(item.millisecondsThisPeriod) }}</span
                    >
                  </v-row>
                  <v-row class="pl-5" style="margin-top: -4px;" no-gutters>
                    <span style="max-width: 10vw" class="text--disabled text-caption text-truncate">
                      {{ getMonthAndYearFromTimestamp(new Date().getTime()) }}
                    </span>
                  </v-row>
                </v-col>
              </v-row>
            </template>

            <template #no-results>
              <v-fade-transition>
                <v-card style="opacity: 0.3" height="200" flat color="transparent">
                  <v-row align="center" style="height:100%">
                    <v-col>
                      <v-row justify="center">
                        <v-icon size="60">mdi-account-off</v-icon>
                      </v-row>
                      <v-row justify="center">
                        <span>No Matches</span>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-fade-transition>
            </template>

            <template #header.data-table-select="{props}">
              <v-row class="fill-height" align="center" no-gutters>
                <v-simple-checkbox
                  :value="selectedPatients.length === filteredPatients.length"
                  v-ripple
                  color="primary"
                  :indeterminate="
                    props.indeterminate ||
                      (selectedPatients.length > 0 && selectedPatients.length !== filteredPatients.length)
                  "
                  @click.stop="toggleSelected(props)"
                ></v-simple-checkbox>

                <data-table-toolbar :selectedPatients="selectedPatients" :tableTitle="'All Enrolled Patients'" />

                <v-spacer />
                <span>
                  <span>{{ (page - 1) * options.itemsPerPage + 1 }}</span
                  ><span> - </span>
                  <span v-if="(page - 1) * options.itemsPerPage + options.itemsPerPage < filteredPatients.length">{{
                    (page - 1) * options.itemsPerPage + options.itemsPerPage
                  }}</span>
                  <span v-else> {{ filteredPatients.length }} </span> of {{ filteredPatients.length }}
                </span>

                <v-btn large :disabled="page === 1" @click="page = page - 1" icon
                  ><v-icon size="25">mdi-chevron-left</v-icon></v-btn
                >

                <v-btn large :disabled="page === pageCount" @click="page = page + 1" icon
                  ><v-icon size="25">mdi-chevron-right</v-icon></v-btn
                >
              </v-row>
            </template>

            <template #no-data>
              <v-fade-transition>
                <v-row align="center">
                  <v-col>
                    <v-row justify="center">
                      <span>No Matching Patient Records</span>
                    </v-row>
                  </v-col>
                </v-row>
              </v-fade-transition>
            </template>
          </v-data-table>
        </v-card>
        <map-detail-menu
          :positionX="mapMenuX"
          :positionY="mapMenuY"
          :showMenu="showMapContextMenu"
          :data="contextMenuData"
        >
        </map-detail-menu>
      </v-container>
    </v-slide-y-reverse-transition>
  </v-card>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import moment from 'moment-timezone'
import consts from '@/consts'
import OrgStatsBar from '../components/populationview/OrgStatsBar.vue'
import UnseenAlertSection from '../components/populationview/UnseenAlertSection.vue'
import HighPriorityPatientSection from '../components/populationview/HighPriorityPatientSection.vue'
import MapView from '@/components/populationview/MapView.vue'
import MapDetailMenu from '@/components/populationview/MapDetailMenu.vue'
import AdminDashboard from '@/components/populationview/AdminDashboard.vue'
import HelperMixin from '@/core/mixins/HelperMixin'
import PatientInfoCell from '@/components/populationview/PatientInfoCell.vue'
import DataTableToolbar from '@/components/populationview/DataTableToolbar.vue'
import AdvancedSearchMenu from '@/components/AdvancedSearchMenu.vue'
import TaggedNotesSection from '../components/populationview/TaggedNotesSection.vue'

export default {
  mixins: [HelperMixin],
  components: {
    OrgStatsBar,
    UnseenAlertSection,
    HighPriorityPatientSection,
    MapView,
    MapDetailMenu,
    AdminDashboard,
    PatientInfoCell,
    DataTableToolbar,
    AdvancedSearchMenu,
    TaggedNotesSection,
  },
  data() {
    return {
      showMap: false,
      showStats: false,
      mapMenuX: 0,
      mapMenuY: 0,
      showMapContextMenu: false,
      contextMenuData: null,
      alertFilterOptions: [
        { text: 'Out of range', value: true },
        { text: 'Within range', value: false },
      ],
      options: {},
      pageCount: 0,
      page: 1,
      activityMenu: false,
      priorityFilter: false,
      newPatientFilter: false,
      selectedAlertFilter: null,
      selectedActivityOption: null,
      selectedQHPOption: null,
      searchInput: null,
      patientTableHeight: 0,
      selectedPatients: [],
      selectedConditions: [],
      selectedTags: [],
      selectedZips: [],
      headers: [
        {
          text: 'Patient',
          align: 'start',
          sortable: true,
          value: 'lastName',
          width: '100px',
        },
        { text: 'BP', value: 'MEASUREMENT_BLOOD_PRESSURE' },
        { text: 'HR', value: 'MEASUREMENT_PULSE' },
        { text: 'GLU', value: 'MEASUREMENT_BLOOD_GLUCOSE' },
        { text: 'ECG', value: 'MEASUREMENT_ECG' },
        { text: 'WT', value: 'MEASUREMENT_BODY_WEIGHT' },
      ],
      ticksLabels: ['24 Hr', '7 days', '30 days', 'All'],
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.getEnrolledPatients()
    this.patientTableHeight = window.innerHeight - 280

    let vm = this
    window.onresize = function() {
      vm.patientTableHeight = window.innerHeight - 280
      vm.renderComponent = false

      vm.$nextTick(() => {
        // Add the component back in
        vm.renderComponent = true
      })
    }
  },
  computed: {
    ...mapState('populationview', ['enrolledPatients', 'patientLoadingProgress', 'refreshingTaskboard']),
    ...mapState('auth', ['partner', 'user']),
    numberOfItemsPerRow() {
      return parseInt((this.patientTableHeight - 60) / 70)
    },
    patientsWithUnseenAlerts() {
      let filteredList = this.enrolledPatients

      filteredList = filteredList.filter(function(e) {
        return e.rpm.newAlerts
      })

      return filteredList
    },
    filterApplied() {
      if (this.priorityFilter) {
        return true
      }

      if (this.newPatientFilter) {
        return true
      }

      if (this.selectedConditions.length > 0) {
        return true
      }

      if (this.selectedTags.length > 0) {
        return true
      }

      if (this.selectedAlertFilter !== null) {
        return true
      }

      if (this.selectedZips.length > 0) {
        return true
      }

      if (this.selectedActivityOption !== null) {
        return true
      }

      if (this.selectedQHPOption !== null) {
        return true
      }

      if (this.searchInput !== null) {
        return true
      }

      return false
    },
    metricList() {
      return consts.metricList
    },
    filteredPatients() {
      let filteredList = this.enrolledPatients
      let vm = this
      filteredList = filteredList.filter(function(e) {
        // Filter out non-priority patients.
        if (vm.priorityFilter && !e.highPriority) {
          return false
        }

        // Filter out non-new patients.
        if (vm.newPatientFilter && !e.newPatient) {
          return false
        }

        // Filter out patients that have no matching conditions.
        if (vm.selectedConditions.length > 0 && !e.conditionsForMatching.some(r => vm.selectedConditions.indexOf(r) >= 0)) {
          return false
        }

        // Filter out patients that have no matching tags.
        if (vm.selectedTags.length > 0 && !e.tags.some(r => vm.selectedTags.indexOf(r) >= 0)) {
          return false
        }

        // Filter based on alert status.
        if (vm.selectedAlertFilter) {
          if (vm.selectedAlertFilter.value && !e.alert) {
            // Filter out patients not in alert.
            return false
          }
          if (!vm.selectedAlertFilter.value && e.alert) {
            // Filter out patients in alert.
            return false
          }
        }

        // Filter out patients that don't live in given zip codes.
        if (vm.selectedZips.length > 0 && vm.selectedZips.indexOf(e.zip) < 0) {
          return false
        }

        // Filter based on activity.
        if (vm.selectedActivityOption) {
          const opt = vm.selectedActivityOption
          if (e.mostRecentDataTimestamp) {
            if (e.mostRecentDataTimestamp < opt.lower || e.mostRecentDataTimestamp > opt.upper) {
              // Most recent data occurred outside of range.
              return false
            }
          } else if (e.rpm && e.rpm.enrollment && e.rpm.enrollment.start) {
            // No data, but enrolled. Patient can still be included in "Patient HASN'T taken a reading since X."
            // if patient was enrolled before X.
            const enrollmentStart = Date.parse(e.rpm.enrollment.start)
            if (opt.lower !== -Infinity || enrollmentStart > opt.upper) {
              // "Patient HASN'T taken..." options have `opt.lower === -Infinity`. Filter out.
              // `enrollmentStart > opt.upper` means that this patient was enrolled more recently than X. Filter out.
              return false
            }
          } else {
            // No data and not enrolled (shouldn't reach here since we filtered down to enrolled earlier).
            return false
          }
        }

        // Filter based on QHP time.
        if (vm.selectedQHPOption) {
          const ms = e.millisecondsThisPeriod || 0
          if (ms > vm.selectedQHPOption.upper || ms < vm.selectedQHPOption.lower) {
            return false
          }
        }

        // Filter by search.
        if (vm.searchInput) {
          let match = (e.smsPhone && e.smsPhone.replace(/[^0-9]/g, '').includes(vm.searchInput)) ||
            e.lastName.toLowerCase().includes(vm.searchInput.toLowerCase()) ||
            e.firstName.toLowerCase().includes(vm.searchInput.toLowerCase()) ||
            (e.ownerPatientID && e.ownerPatientID.toLowerCase().includes(vm.searchInput.toLowerCase()))
          if (!match) {
            return false
          }
        }

        return true
      })

      return filteredList
    },
    patientConditions() {
      let arrayOfArrayOfConditions = this.enrolledPatients.map(a => a.conditions)
      let conditionsWithoutDuplicates = []
      let icd10Codes = []
      arrayOfArrayOfConditions.forEach(patientConditions => {
        patientConditions.forEach(condition => {
          if (!icd10Codes.includes(condition.split('|')[0])) {
            icd10Codes.push(condition.split('|')[0])
            conditionsWithoutDuplicates.push({
              value: condition.split('|')[0],
              text: condition.split('|')[0] + ' ' + condition.split('|')[1],
            })
          }
        })
      })

      conditionsWithoutDuplicates.sort(function(a, b) {
        return a.value - b.value
      })

      return conditionsWithoutDuplicates
    },

    patientTags() {
      let arrayOfArrayOfTags = this.enrolledPatients.map(a => a.tags)

      let tags = []
      arrayOfArrayOfTags.forEach(patientTags => {
        patientTags.forEach(tag => {
          if (!tags.includes(tag)) {
            tags.push(tag)
          }
        })
      })
      return tags.sort()
    },
    patientZips() {
      let arrayOfZips = this.enrolledPatients.map(a => a.zip)

      let zips = []
      arrayOfZips.forEach(patientZip => {
        if (!zips.includes(patientZip)) {
          zips.push(patientZip)
        }
      })
      return zips.sort()
    },
    qhpOptions() {
      let options = []
      options.push({
        text: 'Zero chart time',
        lower: 0,
        upper: 0,
        selectionText: 'Zero chart time',
        icon: 'mdi-clock-check-outline',
        iconColor: 'primary',
        zero: true,
      })
      options.push({
        text: 'Fewer than 10 minutes',
        lower: 1,
        upper: 60000 * 10 - 1,
        selectionText: 'Fewer than 10 min',
        icon: 'mdi-clock-check-outline',
        iconColor: 'primary',
        range: true,
      })
      options.push({
        text: 'Between 10 and 20 minutes',
        lower: 60000 * 10,
        upper: 60000 * 20 - 1,
        selectionText: 'Between 10 and 20 minutes',
        icon: 'mdi-clock-check-outline',
        iconColor: 'primary',
        range: true,
      })
      options.push({
        text: 'Fewer than 20 minutes',
        lower: 1,
        upper: 60000 * 20 - 1,
        selectionText: 'Fewer than 20 Min',
        icon: 'mdi-clock-alert-outline',
        range: true,
      })
      options.push({
        text: '20 minutes or more',
        lower: 60000 * 20,
        upper: Infinity,
        selectionText: '20 Min+',
        icon: 'mdi-clock-check-outline',
        iconColor: 'primary',
      })

      return options
    },
    activityOptions() {
      const options = []
      // get the start of the day
      const startOfToday = new Date()
      startOfToday.setHours(0, 0, 0, 0)
      const endOfToday = new Date(startOfToday)
      endOfToday.setDate(endOfToday.getDate() + 1)
      endOfToday.setMilliseconds(endOfToday.getMilliseconds() - 1)
      options.push({
        text: 'Took a reading today',
        lower: startOfToday.getTime(),
        upper: endOfToday.getTime(),
        selectionText: 'Today',
        icon: 'mdi-calendar-check',
        iconColor: 'primary',
      })
      options.push({
        text: "Didn't take a reading today",
        lower: -Infinity,
        upper: startOfToday.getTime(),
        selectionText: 'Today',
        icon: 'mdi-calendar-remove',
      })

      let startOfYesterday = new Date(startOfToday.valueOf())
      startOfYesterday.setDate(startOfYesterday.getDate() - 1)
      options.push({
        text: 'Took a reading today or yesterday',
        lower: startOfYesterday.getTime(),
        upper: endOfToday.getTime(),
        selectionText: 'Since yesterday',
        icon: 'mdi-calendar-check',
        iconColor: 'primary',
      })
      options.push({
        text: "Didn't take a reading today or yesterday",
        lower: -Infinity,
        upper: startOfYesterday.getTime(),
        selectionText: 'Since yesterday',
        icon: 'mdi-calendar-remove',
      })

      let sevenDaysAgo = new Date(startOfToday.valueOf())
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)
      options.push({
        text: 'Took a reading in the last 7 days',
        lower: sevenDaysAgo.getTime(),
        upper: endOfToday.getTime(),
        selectionText: '7 Days',
        icon: 'mdi-calendar-check',
        iconColor: 'primary',
      })
      options.push({
        text: "Hasn't taken a reading in 7+ days",
        lower: -Infinity,
        upper: sevenDaysAgo.getTime(),
        selectionText: '7+ Days',
        icon: 'mdi-calendar-remove',
      })

      let thirtyDaysAgo = new Date(startOfToday.valueOf())
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
      options.push({
        text: 'Took a reading in the last 30 days',
        lower: thirtyDaysAgo.getTime(),
        upper: endOfToday.getTime(),
        selectionText: '30 Days',
        icon: 'mdi-calendar-check',
        iconColor: 'primary',
      })
      options.push({
        text: "Hasn't taken a reading in 30+ days",
        lower: -Infinity,
        upper: thirtyDaysAgo.getTime(),
        selectionText: '30+ Days',
        icon: 'mdi-calendar-remove',
      })

      return options
    },
  },
  methods: {
    ...mapMutations('populationview', ['setTaskboardFreshness', 'setEnrolledPatients']),
    ...mapActions('populationview', ['getEnrolledPatients']),
    ...mapActions('patient', [
      'prepareForSigningOut',
      'generateFacesheet',
      'clearPatient',
      'manageWatcher',
      'preparePatientObject',
    ]),
    sendMassText() {
      this.$refs.massText.open()
    },
    zipCodeSelected(zipHere) {
      let match = this.patientZips.find(zip => zip.text === zipHere)
      if (match) {
        this.selectedZips = [match]
      }
    },
    hoverOverMapPoint(event) {
      this.mapMenuX = event.x
      this.mapMenuY = event.y
      this.showMapContextMenu = true
      this.contextMenuData = event.data
    },
    cancelHoverOverMapPoint() {
      this.mapMenuX = null
      this.mapMenuY = null
      this.showMapContextMenu = null
      this.contextMenuData = null
    },
    applyConditionFilter(condition) {
      const index = this.selectedConditions.indexOf(condition.title.split(' ')[0])

      if (index > -1) {
        this.selectedConditions.splice(index, 1)
      } else {
        this.selectedConditions.push(condition.title.split(' ')[0])
      }
    },
    manageTags() {
      let patientToPass = this.selectedPatients[0]
      const found = this.enrolledPatients.find(patient => patient._id === patientToPass._id)
      if (found) {
        this.$refs.tagManagerDialog.open({ patient: patientToPass })
      }
    },
    closeTagMenu() {
      let vm = this
      setTimeout(function() {
        vm.tagMenu = false
      }, 200)
    },
    toggleSelected() {
      if (this.selectedPatients.length > 0) {
        this.selectedPatients = []
      } else {
        this.selectedPatients = this.$refs.dataTable.$children[0].filteredItems
      }
    },
    removeAllFilters() {
      this.selectedConditions = []
      this.selectedTags = []
      this.selectedAlertFilter = null
      this.selectedZips = []
      this.selectedActivityOption = null
      this.selectedQHPOption = null
      this.priorityFilter = false
      this.newPatientFilter = false
    },
    removeSpecificConditionFilter(condition) {
      const index = this.selectedConditions.indexOf(condition)
      if (index > -1) {
        this.selectedConditions.splice(index, 1)
      }
    },
    removeSpecificZipFilter(zip) {
      const index = this.selectedZips.indexOf(zip)
      if (index > -1) {
        this.selectedZips.splice(index, 1)
      }
    },
    removeSpecificTagFilter(tag) {
      const index = this.selectedTags.indexOf(tag)
      if (index > -1) {
        this.selectedTags.splice(index, 1)
      }
    },
    relativeTime(value) {
      if (value) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .fromNow()
        }
      }
      return ''
    },
    msToTime(s) {
      if (!s) {
        return '0h 0m 0s'
      }
      let seconds = Math.floor((s / 1000) % 60)
      let minutes = Math.floor((s / (1000 * 60)) % 60)
      let hours = Math.floor(s / (1000 * 60 * 60))

      hours = hours < 10 ? '' + hours : hours
      minutes = minutes < 10 ? '' + minutes : minutes
      seconds = seconds < 10 ? '' + seconds : seconds

      return hours + 'h ' + minutes + 'm ' + seconds + 's'
    },
    handleClick(patient) {
      this.clearPatient()
      this.$router.push({
        name: 'PatientFeed',
        params: { id: patient._id },
      })
    },
  },
}
</script>

<style lang="css" scoped>
.row-pointer {
  cursor: pointer;
}
</style>

<style></style>
