<template>
  <v-col>
    <v-row no-gutters align="center">
      <span v-if="selectedPatients.length === 0" class="text-body-1 ml-2 font-weight-medium">{{ tableTitle }}</span>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-scale-transition hide-on-leave origin="center">
            <v-btn
              v-show="selectedPatients.length > 0"
              class="mx-1"
              depressed
              dark
              color="grey darken-1"
              v-bind="attrs"
              v-on="on"
              @click="exportSelectedPatientsCSV(selectedPatients)"
              icon
            >
              <v-icon size="23">mdi-cloud-download-outline</v-icon>
            </v-btn>
          </v-scale-transition>
        </template>
        Export Patient List
      </v-tooltip>

      <mass-text-menu :selectedPatients="selectedPatients" ref="massText" />
      <patient-tag-manager-dialog :selectedPatients="selectedPatients" ref="tagManagerDialog" />
      <v-scale-transition hide-on-leave origin="start">
        <v-chip label v-show="selectedPatients.length > 0" class="ml-2" color="primary" small>
          <span
            >{{ selectedPatients.length }} Patient<span v-if="selectedPatients.length !== 1">s </span> Selected</span
          >
        </v-chip>
      </v-scale-transition>
    </v-row>
  </v-col>
</template>

<script>
import MassTextMenu from '@/components/dialogs/MassTextMenu.vue'
import PatientTagManagerDialog from '@/components/dialogs/PatientTagManagerDialog.vue'
import { mapActions } from 'vuex'

export default {
  components: { PatientTagManagerDialog, MassTextMenu },
  props: {
    tableTitle: {
      type: String,
      default: 'Title',
    },
    selectedPatients: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  methods: {
    exportSelectedPatientsCSV() {
      let payload = {}
      payload.array = this.selectedPatients

      payload.title = 'Selected Patients'

      payload.metrics = []
      // this.configuration.displayedDashboardMetrics.forEach(item => {
      //   if (item === 'MEASUREMENT_PULSE') {
      //     payload.metrics.push('hr')
      //   }

      //   if (item === 'MEASUREMENT_BLOOD_PRESSURE') {
      //     payload.metrics.push('bp')
      //   }

      //   if (item === 'MEASUREMENT_BLOOD_GLUCOSE') {
      //     payload.metrics.push('glu')
      //   }

      //   if (item === 'MEASUREMENT_BODY_WEIGHT') {
      //     payload.metrics.push('wt')
      //   }
      // })

      this.createDashboardCSV(payload)
    },
    ...mapActions('populationview', ['createDashboardCSV']),
  },
}
</script>

<style></style>
