<template>
  <v-dialog v-model="menu" width="500">
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn v-show="unenrolledPatients.length > 0" class="mr-1" icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
            <v-icon>mdi-archive-outline</v-icon>
          </v-btn>
        </template>
        <span>Search Archived Patients</span>
      </v-tooltip>
    </template>

    <v-card class="pb-4">
      <v-row align="center" no-gutters justify="space-between" class="font-weight-bold text-h6 pa-3 px-4">
        <span>Archived Patients</span>
        <v-btn icon @click="menu = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>

      <v-text-field
        label="Search archived patients"
        prepend-inner-icon="mdi-magnify"
        class="pt-0 px-4 mb-3 mx-0"
        hide-details
        background-color="grey lighten-4"
        full-width
        single-line
        solo
        flat
        v-model="search"
        return-object
        clearable
      >
      </v-text-field>

      <v-card class="pt-0 px-0 mb-3 mx-4" flat color="grey lighten-4">
        <v-data-table
          dense
          fixed-header
          height="300"
          :headers="headers"
          hide-default-header
          hide-default-footer
          :items="filteredUnenrolled"
          items-per-page="50"
          @click:row="handleClick"
          class="row-pointer grey lighten-4 "
          style="background-color: transparent"
        >
          <template v-slot:item.lastName="{ item }">
            <v-row justify="start" no-gutters>
              <span class="font-weight-regular text-body-2">
                {{ item.firstName }}
                <span class="font-weight-bold text-body-2">{{ item.lastName }}</span>
                <span class="text--secondary ml-1" v-if="item.ownerPatientID">{{ item.ownerPatientID }}</span>
              </span>
            </v-row>
          </template>

          <template #no-results>
            <v-fade-transition>
              <v-card style="opacity: 0.3" height="300" flat color="transparent">
                <v-row align="center" justify="center" class="fill-height">
                  <div>
                    <v-row justify="center">
                      <v-icon size="100">mdi-account-off</v-icon>
                    </v-row>
                    <v-row justify="center">
                      <span>No Matching Records</span>
                    </v-row>
                  </div>
                </v-row>
              </v-card>
            </v-fade-transition>
          </template>

          <template v-slot:item.rpmMostRecentData="{ item }">
            <v-row class="text-caption" no-gutters>
              Last Active:
              <span v-if="item.rpmMostRecentData" class="ml-1 font-weight-bold">
                {{ relativeTime(item.rpmMostRecentData) }}
              </span>
              <span v-else class="text--secondary ml-1 font-weight-bold">
                Never Active
              </span>
            </v-row>
          </template>
        </v-data-table>
      </v-card>

      <v-row class="px-4 pt-4" justify="space-between" no-gutters>
        <v-btn depressed @click="menu = false">Cancel</v-btn>
        <v-btn color="primary" depressed @click="menu = false">Done</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import HelperMixin from '@/core/mixins/HelperMixin'
import { mapState, mapActions, mapMutations } from 'vuex'
import moment from 'moment-timezone'

export default {
  mixins: [HelperMixin],
  name: 'advancedSearchMenu',
  components: {},
  props: {
    enablePopups: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      search: null,
      menu: false,
    }
  },
  methods: {
    ...mapMutations('patient', ['setPatient']),
    ...mapActions('patient', ['clearPatient', 'manageWatcher', 'preparePatientObject']),
    ...mapActions('auth', ['userActivity']),
    relativeTime(value) {
      if (value) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .fromNow()
        }
      }
      return ''
    },
    handleClick(patient) {
      this.clearPatient()
      this.setPatient(patient)
      this.manageWatcher(patient._id)
      this.$router.push({
        name: 'PatientFeed',
        params: { id: patient._id },
      })

      this.userActivity({ patient: patient._id, type: 'view_chart' })
    },
  },
  computed: {
    ...mapState('patient', ['patient']),
    ...mapState('populationview', ['unenrolledPatients']),
    headers() {
      let headers = [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'lastName',
        },
      ]
      headers.push({
        text: 'Last Active',
        align: 'center',
        value: 'rpmMostRecentData',
        width: 140,
        filterable: false,
      })

      return headers
    },
    filteredUnenrolled() {
      let filtered = this.unenrolledPatients

      if (this.search) {
        let normalizedSearch = this.search.toLowerCase()

        filtered = filtered.filter(function(e) {
          return (
            e.firstName.toLowerCase().includes(normalizedSearch) ||
            e.lastName.toLowerCase().includes(normalizedSearch) ||
            (e.ownerPatientID && e.ownerPatientID.includes(normalizedSearch)) ||
            (e.displayBirthdate && e.displayBirthdate.includes(normalizedSearch)) ||
            (e.fullName && e.fullName.toLowerCase().includes(normalizedSearch))
          )
        })
      }

      return filtered
    },
  },
  watch: {
    menu(val) {
      if (!val) {
        this.search = null
      }
    },
  },
  mounted() {},
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
