<template>
  <v-row style="height:42px; overflow:hidden" justify="start" align="start" no-gutters>
    <org-stats-card
      title="Patients"
      :value="filteredArray.length"
      dark
      color="navColor"
      :subtitle="((filteredArray.length / enrolledPatients.length) * 100).toFixed(1) + '%'"
      @click="patientsClicked()"
    />

    <org-stats-card
      title="Priority"
      :value="highPriorityPatients"
      dark
      color="primary"
      :subtitle="((highPriorityPatients / filteredArray.length) * 100).toFixed(1) + '%'"
      @click="priorityClicked"
    />

    <org-stats-card
      title="Active Today"
      :value="readingTodayCount"
      :subtitle="((readingTodayCount / filteredArray.length) * 100).toFixed(1) + '%'"
      color="green"
      dark
      @click="activeTodayClicked"
    />

    <org-stats-card
      title="Out of Range"
      :value="alertCount"
      :subtitle="((alertCount / filteredArray.length) * 100).toFixed(1) + '%'"
      color="yellow"
      @click="alertClicked"
    />

    <org-stats-card
      v-show="false"
      title="Unseen Alerts"
      :value="unseenAlerts"
      :subtitle="((unseenAlerts / alertCount) * 100).toFixed(1) + '%'"
      color="yellow"
    />

    <!-- <org-stats-card
      v-for="(condition, index) in conditionCounts"
      :key="index"
      v-show="index < 2"
      :title="condition.title"
      :value="condition.count"
      dark
      color="grey darken-2"
      :subtitle="((condition.count / filteredArray.length) * 100).toFixed(1) + '%'"
      @click="conditionClicked(condition)"
    /> -->

    <org-stats-card
      title="Active, Last 7 Days"
      :value="reading7DaysCount"
      :subtitle="((reading7DaysCount / filteredArray.length) * 100).toFixed(1) + '%'"
      color="green lighten-2"
      dark
      @click="activeSevenClicked"
    />

    <org-stats-card
      v-if="new30DaysCount > 0"
      title="New, Last 30 Days"
      :value="new30DaysCount"
      :subtitle="((new30DaysCount / filteredArray.length) * 100).toFixed(1) + '%'"
      color="orange"
      dark
      @click="newPatientsClicked"
    />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import OrgStatsCard from './OrgStatsCard.vue'

export default {
  components: {
    OrgStatsCard,
  },
  props: {
    filteredArray: null,
    filterApplied: {
      default: false,
    },
    allowExpansion: {
      default: false,
    },
    patientConditions: null,
  },
  data() {
    return {
      expandedView: false,
      revealExpanded: false,
    }
  },
  watch: {
    expandedView(val) {
      if (val) {
        setTimeout(() => (this.revealExpanded = true), 300)
      } else {
        this.revealExpanded = false
      }
    },
    allowExpansion(val) {
      if (!val) {
        this.expandedView = false
      }
    },
  },
  methods: {
    patientsClicked() {
      this.$emit('clearFilters')
    },

    priorityClicked() {
      this.$emit('priority')
    },
    activeSevenClicked() {
      this.$emit('activeSeven')
    },
    alertClicked() {
      this.$emit('alert')
    },
    activeTodayClicked() {
      this.$emit('activeToday')
    },
    newPatientsClicked() {
      this.$emit('newPatients')
    },
    conditionClicked(condition) {
      console.log('trying to send a condition')
      console.log(condition)
      this.$emit('condition', condition)
    },
  },
  computed: {
    ...mapState('auth', ['partner', 'configuration', 'user', 'appTheme', 'viewerMode']),
    ...mapState('taskboard', ['patientMetricTypes', 'patientDevices']),
    ...mapState('populationview', ['enrolledPatients']),
    fixedCardHeight() {
      if (this.expandedView) {
        return null
      }
      return 55
    },
    totalPatientTitle() {
      if (this.filterApplied) {
        return 'Filtered'
      }
      return 'Total Enrolled'
    },
    conditionCounts() {
      let compiledCounts = []

      this.patientConditions.forEach(condition => {
        let conditionPatients = this.filteredArray.filter(function(e) {
          return e.conditionsForMatching.includes(condition.value)
        })

        compiledCounts.push({ title: condition.text, count: conditionPatients.length })
      })
      return compiledCounts.sort((a, b) => b.count - a.count)
    },
    deviceCounts() {
      let compiledCounts = []

      // this.patientDevices.forEach(deviceOption => {
      //   let devicePatients = this.filteredArray.filter(function(e) {
      //     let included = false
      //     let matched = e.devicesInUse.find(device =>
      //       device.includes(deviceOption.text)
      //     )
      //     if (matched) {
      //       included = true
      //     }

      //     return included
      //   })

      //   let returnObject = {
      //     device: deviceOption.text,
      //     count: devicePatients.length,
      //   }
      //   if (devicePatients.length > 0) {
      //     compiledCounts.push(returnObject)
      //   }
      // })
      return compiledCounts.sort((a, b) => b.count - a.count)
    },
    alertCount() {
      let alertPatients = this.filteredArray.filter(function(e) {
        return e.alert
      })
      return alertPatients.length
    },
    goodCount() {
      let alertPatients = this.filteredArray.filter(function(e) {
        return !e.alert && e.rpm.enrollment.firstData
      })
      return alertPatients.length
    },
    readingTodayCount() {
      let startOfToday = new Date()
      startOfToday.setHours(0, 0, 0, 0)

      let subsetPatients = this.filteredArray.filter(function(e) {
        return e.mostRecentDataTimestamp > startOfToday.getTime()
      })
      return subsetPatients.length
    },
    readingsOneWeekAgoToday() {
      let sevenDays = new Date()
      sevenDays.setDate(sevenDays.getDate() - 7)
      sevenDays.setHours(0, 0, 0, 0)
      let dateInUTC = sevenDays.toISOString()

      let subsetPatients = this.filteredArray.filter(function(e) {
        console.log(dateInUTC)
        return (
          e.rpm &&
          e.rpm.activity &&
          e.rpm.activity.dates &&
          e.rpm.activity.dates.length > 0 &&
          e.rpm.activity.dates.includes(dateInUTC.substring(0, 10) + e.rpm.activity.dates[0].substring(10, 20))
        )
      })
      return subsetPatients.length
    },
    reading7DaysCount() {
      let sevenDays = new Date()
      sevenDays.setDate(sevenDays.getDate() - 7)
      sevenDays.setHours(0, 0, 0, 0)

      let subsetPatients = this.filteredArray.filter(function(e) {
        return e.mostRecentDataTimestamp > sevenDays.getTime()
      })
      return subsetPatients.length
    },
    new30DaysCount() {
      let thirtyDays = new Date()
      thirtyDays.setDate(thirtyDays.getDate() - 30)
      thirtyDays.setHours(0, 0, 0, 0)

      console.log(this.filteredArray)

      let subsetPatients = this.filteredArray.filter(function(e) {
        let shouldReturn = false
        if (e.rpm && e.rpm.enrollment && e.rpm.enrollment.start) {
          let startDate = Date.parse(e.rpm.enrollment.start)
          if (startDate > thirtyDays.getTime()) {
            shouldReturn = true
          }
        }
        return shouldReturn
      })
      return subsetPatients.length
    },
    unseenAlerts() {
      let subsetPatients = this.filteredArray.filter(function(e) {
        return e.rpm.newAlerts
      })
      return subsetPatients.length
    },
    highPriorityPatients() {
      let filteredList = this.filteredArray

      filteredList = filteredList.filter(function(e) {
        return e.highPriority
      })

      return filteredList.length
    },

    highCompliancePatients() {
      let filteredList = this.filteredArray

      filteredList = filteredList.filter(function(e) {
        return e.highCompliance
      })

      return filteredList.length
    },

    qualifiedRecently() {
      let filteredList = this.filteredArray

      filteredList = filteredList.filter(function(e) {
        return e.recentQualifications > 0
      })

      return filteredList.length
    },

    onFire() {
      let filteredList = this.filteredArray

      filteredList = filteredList.filter(function(e) {
        return e.onFire
      })

      return filteredList.length
    },

    lowCompliancePatients() {
      let filteredList = this.filteredArray

      filteredList = filteredList.filter(function(e) {
        return e.lowCompliance
      })

      return filteredList.length
    },

    readingsNotLastWeekButTheWeekBefore() {
      let sevenDays = new Date()
      sevenDays.setDate(sevenDays.getDate() - 1)
      sevenDays.setHours(0, 0, 0, 0)
      let dateInUTC = sevenDays.toISOString()

      let arrayOfDates = []
      let ending = ''
      if (this.enrolledPatients[0].rpm.activity.dates.length > 0) {
        ending = this.enrolledPatients[0].rpm.activity.dates[0].substring(10, 20)
      }
      for (let i = 8; i < 15; i++) {
        let item = new Date()
        item.setDate(item.getDate() - i)
        item.setHours(0, 0, 0, 0)
        let dateInUTCitem = item.toISOString()
        console.log(dateInUTCitem)
        arrayOfDates.push(dateInUTCitem.substring(0, 10) + ending)
      }

      arrayOfDates.push(dateInUTC.substring(0, 10) + ending)

      console.log(arrayOfDates)

      let subsetPatients = this.filteredArray.filter(function(e) {
        console.log(dateInUTC)
        return (
          e.rpm &&
          e.rpm.activity &&
          e.rpm.activity.dates &&
          e.rpm.activity.dates.length > 0 &&
          e.rpm.activity.dates.some(r => arrayOfDates.indexOf(r) >= 0)
        )
      })
      return subsetPatients.length
    },
  },
}
</script>

<style scoped>
.stickyHeader {
  position: sticky;
  top: 111px;
  z-index: 3;
  background-color: white;
  height: 60px;
}
.normalHeader {
  height: 60px;
}
.v-icon.active {
  transform: rotate(-180deg);
}
</style>
