<template>
  <v-container fluid class="pa-0">
    <portal to="appBarContentLeft">
      <v-row class="flex-nowrap" align="center" justify="space-between" no-gutters>
        <span v-if="partner" class="text-no-wrap font-weight-light text-h5 mr-4">
          {{ arrayToUse[selectedCategory].title }}
        </span>
      </v-row>
    </portal>

    <portal to="appBarContentExtension">
      <v-card class="flex" flat style="border-radius: 5px; overflow:hidden">
        <v-row class="flex" no-gutters>
          <v-col v-for="(cat, item) in arrayToUse" :key="item">
            <v-card @click="cardClick(item)" dark tile class="flex" flat :color="cat.color">
              <v-row no-gutters class="fill-height text-h6 font-weight-bold" justify="center" align="center">{{
                cat.count
              }}</v-row>
              <v-row
                v-show="false"
                no-gutters
                class="fill-height text-caption"
                justify="center"
                style="margin-top: -6px;"
                >{{ cat.title }}</v-row
              >
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-card height="40" flat>
            <v-simple-checkbox color="primary"></v-simple-checkbox>
          </v-card>
        </v-row>
      </v-card>
    </portal>

    <portal to="subdrawer">
      <v-list nav>
        <v-row class="py-2 pb-2" no-gutters justify="center">
          <span class="text-no-wrap font-weight-light text-h5">Engagement</span>
        </v-row>
        <v-card min-height="160" flat class="flex mt-4 mb-4">
          <v-card flat style="height: 100%; width: 100%; position: absolute">
            <v-row no-gutters class="fill-height" align="center" justify="center">
              <div>
                <v-row style="font-size: 20px" class="font-weight-bold" justify="center" no-gutters>
                  {{ Math.round((arrayToUse[selectedCategory].count / patientCount) * 100) }}%
                </v-row>
                <v-row class="text-caption" justify="center" no-gutters>
                  {{ arrayToUse[selectedCategory].title }}
                </v-row>
              </div>
            </v-row>
          </v-card>
          <v-row no-gutters align="center" class="fill-height" justify="center">
            <vue-apex-charts ref="chart" width="90%" :series="chartData" :options="chartOptions"></vue-apex-charts>
          </v-row>
        </v-card>

        <v-tooltip v-for="(cat, index) in arrayToUse" :key="index" max-width="300" color="grey darken-4" right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              :input-value="selectedCategory === index"
              color="primary"
              active-class="font-weight-bold"
              v-bind="attrs"
              v-on="on"
              @click="cardClick(index)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ cat.title }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip dark :color="cat.color" small> {{ cat.count }} </v-chip>
              </v-list-item-action>
            </v-list-item>
          </template>
          <span>{{ cat.description }}</span>
        </v-tooltip>
      </v-list>
    </portal>

    <v-tabs-items style="background-color: white" v-model="selectedCategory">
      <v-tab-item v-for="(item, count) in arrayToUse" :key="count">
        <patient-activity-data-table :categoryTitle="item.fullTitle" :patientList="item.list" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import PatientActivityDataTable from '@/components/PatientActivityDataTable.vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: { PatientActivityDataTable, VueApexCharts },
  data() {
    return {
      selectedCategory: 0,
      mode: 0,
    }
  },

  computed: {
    ...mapState('populationview', ['enrolledPatients', 'patientLoadingProgress', 'refreshingTaskboard']),
    ...mapState('auth', ['partner', 'user']),
    ...mapState('engagement', ['lastCategory']),

    doingGreat() {
      return this.enrolledPatients.filter(function(e) {
        return !e.possibleOffTrack && !e.unenrollCandidate
      })
    },
    offTrack() {
      return this.enrolledPatients.filter(function(e) {
        return e.possibleOffTrack && !e.unenrollCandidate
      })
    },
    moderateRisk() {
      return this.offTrack.filter(function(e) {
        return e.perfectionNeeded <= 80
      })
    },
    severeRisk() {
      return this.offTrack.filter(function(e) {
        return e.perfectionNeeded > 80 && e.perfectionNeeded <= 100
      })
    },
    ineligible() {
      return this.offTrack.filter(function(e) {
        return e.perfectionNeeded > 100
      })
    },
    inactive() {
      return this.enrolledPatients.filter(function(e) {
        return e.unenrollCandidate
      })
    },
    arrayToUse() {
      let combinedCategories = [
        {
          order: 0,
          count: this.doingGreat.length,
          color: '#1A76F2',
          title: 'On Track',
          list: this.doingGreat,
          fullTitle: 'On Track',
          icon: 'mdi-thumb-up',
          total: Math.floor((this.doingGreat.length / this.enrolledPatients.length) * 100),
          description:
            'Patients who are currently projected to complete 16 days of readings within their billing period',
        },
        {
          order: 1,
          count: this.moderateRisk.length,
          color: '#F9A825',
          title: 'Moderate Risk',
          list: this.moderateRisk,
          fullTitle: 'Moderate Risk',
          icon: 'mdi-alert-circle',
          total: Math.floor((this.moderateRisk.length / this.enrolledPatients.length) * 100),
          description:
            'Patients that need to take a reading between 50% and 80% of the days remaining in their billing period to meet a minimum of 16 days of readings.',
        },
        {
          order: 2,
          count: this.severeRisk.length,
          color: '#E53935',
          title: 'Severe Risk',
          list: this.severeRisk,
          fullTitle: 'Severe Risk',
          icon: 'mdi-alert-circle',
          total: Math.floor((this.severeRisk.length / this.enrolledPatients.length) * 100),
          description:
            'Patients that need to take a reading between 80% and 100% of the days remaining in their billing period to meet a minimum of 16 days of readings.',
        },
        {
          order: 3,
          count: this.ineligible.length,
          color: '#424242',
          title: 'Ineligible',
          list: this.ineligible,
          fullTitle: 'Ineligible',
          icon: 'mdi-alert-octagon',
          total: Math.floor((this.ineligible.length / this.enrolledPatients.length) * 100),
          description:
            'Patients who are projected to be unable to meet the minumum of 16 days of readings in the current billing period.',
        },
        {
          order: 4,
          count: this.inactive.length,
          color: '#000000',
          title: 'Inactive',
          list: this.inactive,
          fullTitle: 'Inactive',
          icon: 'mdi-close-circle',
          total: Math.floor((this.inactive.length / this.enrolledPatients.length) * 100),
          description: 'Patients who have not taken readings within the last month.',
        },
      ]

      return combinedCategories
    },
    chartOptions() {
      let vm = this
      let options = {
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: false,
                name: {
                  show: false,
                },
                value: {
                  formatter: function(item) {
                    return Math.ceil((item / vm.patientCount) * 100) + '%'
                  },
                },
              },
            },
          },
        },
        chart: {
          type: 'donut',
          selection: {
            enabled: false,
            colors: this.arrayToUse.map(x => x.color),
          },
          events: {
            dataPointSelection: function(_, chartContext, config) {
              vm.cardClick(config.dataPointIndex)
              //vm.selectedCategory = config.dataPointIndex
              //console.log(vm.$refs.chart.chart.w.globals.selectedDataPoints)
            },
            dataPointMouseEnter: function(event) {
              event.path[0].style.cursor = 'pointer'
            },
            dataPointMouseLeave: function(event) {
              console.log(event)
            },
          },
        },
        labels: this.arrayToUse.map(x => x.title),
        dataLabels: {
          enabled: false,
          colors: this.arrayToUse.map(x => x.color),
        },
        legend: {
          show: false,
        },
        colors: this.arrayToUse.map(x => x.color),
      }
      return options
    },
    chartData() {
      return this.arrayToUse.map(x => x.count)
    },
    patientCount() {
      return this.arrayToUse.map(x => x.count).reduce((a, b) => a + b)
    },
  },
  methods: {
    ...mapMutations('engagement', ['setLastCategory']),
    cardClick(cat) {
      this.selectedCategory = cat
      this.setLastCategory(cat)
      if (
        this.$refs.chart &&
        this.$refs.chart.chart.w.globals.selectedDataPoints[0] &&
        this.$refs.chart.chart.w.globals.selectedDataPoints[0][0] === cat
      ) {
        console.log('the same is selected')
      } else {
        this.$refs.chart.toggleDataPointSelection(cat)
      }
    },
  },
  mounted() {
    this.selectedCategory = this.lastCategory
  },
}
</script>

<style lang="scss" scoped>
.progress-circular-underlay-stroke {
  color: blue;
}
.inactiveClass {
  opacity: 0.6;
}
</style>
